import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { useEffect, useState, useRef } from "react";
import AddNewInventoryCount from "./AddNewInventoryCount";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import DeleteModal from "../DeleteModal";
import {FaCircleNotch, FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdViewList, MdSync } from "react-icons/md";
import ImportIngredientsInventory from "../imports/ImportIngredientsInventory";
import { saveDataToStore, getDataFromStore, getItemByKey, deleteDataFromStore } from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const STORE_NAME = "inventory-list";
const DB_NAME = "inventory-db";
const INGREDIENT_DB = "ingredientDB";
const INGREDIENT_STORE = "ingredienStore";
const inventoryUpdateDB = "inventory-update";
const inventoryUpdateStore = "inventory-update";
const ingredientbylocation = 'ingredientByLocation';
const ingredientbylocationStore = 'ingredientByLocation';
const Inventory = () => {
  const [hasError, handleHttpRequest] = useHttp();
  const [inventories, setInventories] = useState([]);
 
  const [isInventoryUpdated, setIsInventoryUpdated] = useState(false);
  const isOnline = useNetworkStatus();
  const [loading, setLoading] = useState(false);
  const deleteIdRef = useRef(0);

  // Utility function to display toast notifications
  const showToast = (type, message) => {
    type === "success" ? toast.success(message) : toast.error(message);
  };

  const fetchOfflineData = async () => {
    const cachedData = await getDataFromStore(DB_NAME, STORE_NAME);
    setInventories(cachedData);
    console.log("inevntory:====="+cachedData);
  };

  const fetchInventoryData = async () => {
    try {
      const response = await handleHttpRequest({ page: "inventory-counts", method: "GET" });
      if (!response.status) {
        setInventories(response);
        await saveDataToStore(DB_NAME, STORE_NAME, response,null,'id',true);
      } else {
        showToast("error", "Failed to fetch inventory data");
      }
    } catch (error) {
      showToast("error", "An error occurred while fetching inventory data");
    }
  };

  const syncOfflineData = async () => {
    const offlineData = await getDataFromStore("inventory-count", "inventory-count", "storage_location_id");
    if (offlineData?.length) {
      for (const data of offlineData) {
        await handleHttpRequest({ page: "inventory-count/add", method: "POST", data });
        await deleteDataFromStore("inventory-count", "inventory-count", data.storage_location_id);
      }
      showToast("success", "Offline data synced");
    }
  };

  const saveIngredientsForOffline = async () => {
    try {
      const response = await handleHttpRequest({ page: "ingredients", method: "GET" });
   
      if (!hasError && response.message) {
        await saveDataToStore(INGREDIENT_DB, INGREDIENT_STORE, response.message,null,'id',true);
     
      } else {
      }
    } catch (error) {
     
    }
  };

  const saveIngredientsByLocation=async(storage_location_id)=>{
    const response = await handleHttpRequest({
      page: `ingredients-by-location/${storage_location_id}`,
      method: "GET",
    });
    await saveDataToStore(ingredientbylocation, ingredientbylocationStore, response.message,null,'id',true);
  };

  const checkDataOffline = async (id) => {
    const offlineUpdates = await getItemByKey(inventoryUpdateDB, inventoryUpdateStore, id);
    return (Object.keys(offlineUpdates).length > 0);
  };
  
  const syncOfflineUpdates = async (id) => {
    setLoading(true);
    const offlineUpdates = await getItemByKey(inventoryUpdateDB, inventoryUpdateStore, id);
    if (Object.keys(offlineUpdates).length > 0){
    const offlineUpdatesArray = Object.values(offlineUpdates).filter(item => typeof item === 'object');
      const response = await handleHttpRequest({
        page: `ingredients-inventory/update/${id}`,
        method: "PUT",
        data: { inventories: [...offlineUpdatesArray] }
      });

      if (!response.state) {
        showToast("success", "Offline updates synced successfully");
        await deleteDataFromStore(inventoryUpdateDB, inventoryUpdateStore, id);
        await fetchInventoryData();
      }
     } else {
        showToast("warning", "Some updates couldn't be synced. Please check server response.");
      }
      setLoading(false);
  };

  useEffect(() => {
    if (!isOnline) {
      showToast("warning", "You are offline. Some features may not be available.");
      fetchOfflineData();
    } else {
      (async () => {
        await syncOfflineData();
        await saveIngredientsForOffline();
        await fetchInventoryData();
      })();
    }
  }, [isOnline, isInventoryUpdated]);


  const COLUMNS = [
    { Header: "Inventory Date", accessor: "date_time" },
    { Header: "Inventory Location", accessor: "storagelocation.name" },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row, value }) => (
        <div>
          <button className="btn btn-link icontheme" style={{ color: "#FF7300" }}>
            <Link
              to={row.original.is_commited !== 1 ? `/update-inventory-count/${value}` : `/view-inventory-count/${value}`}
              className="themecolor"
            >
              {row.original.is_commited !== 1 ? <FaRegEdit /> : <MdViewList />}
            </Link>
          </button>

          <button
            className="btn btn-link iconthemedel"
            style={{ color: "#676767" }}
            onClick={() => openDeleteModal(value)}
          >
            <RiDeleteBin6Line />
          </button>

          {/* Conditionally render the sync button based on offline status */}
          {row.original.is_commited !== 1 && checkDataOffline(value) ? (
            <button
              className="btn btn-link iconSync"
              style={{ color: "#676767" }}
              onClick={() => syncOfflineUpdates(value)}
             >
              <MdSync />
            </button>
          ) : (
            <button className="btn btn-link iconSync" style={{ color: "#676767" }} disabled>
              <MdSync />
            </button>
          )}
        </div>
      ),
    },
  ];
  
  const defaultSortBy = [
    {
      id: "date_time", // Column accessor
      desc: true, // Ascending order
    },
  ];

  // Modal Handlers
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isIngredientImportOpen, setIngredientImportOpen] = useState(false);

  const toggleAddModal = () => setAddModalOpen(!isAddModalOpen);
  const openDeleteModal = (id) => {
    deleteIdRef.current = id;
    setDeleteModalOpen(true);
  };

  // Modal actions
  const handleInventorySave = async (message, status) => {
    setAddModalOpen(false);
    setIsInventoryUpdated(!isInventoryUpdated);
    showToast(status === 200 ? "info" : "error", message);
    await fetchInventoryData();
  };

  const handleDelete = async (data) => {
    setDeleteModalOpen(false);
    showToast("info", data[0]);
    setIsInventoryUpdated(!isInventoryUpdated);
    await fetchInventoryData();
  };

  const handleImportSuccess = (response) => {
    setIngredientImportOpen(false);
    showToast("info", response);
  };

  const handleImportError = (message) => {
    setIngredientImportOpen(false);
    showToast("error", message);
  };

  return (
    <> {loading && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hourglass-spinner">
          <FaCircleNotch className="spinner-icon" />
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )}
      <MainPanel>
        {isIngredientImportOpen && (
          <ImportIngredientsInventory
            onClose={() => setIngredientImportOpen(false)}
            onImport={handleImportSuccess}
            onError={handleImportError}
          />
        )}
        {isAddModalOpen && <AddNewInventoryCount saveClick={handleInventorySave} onClick={toggleAddModal} />}
        {isDeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdRef.current}
            onClick={() => setDeleteModalOpen(false)}
            onDelete={handleDelete}
            item="inventory-count"
            name="inventory count"
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <h4 className="card-title">
              Inventory Count
              <button className="btn btn-outline-light ms-3" title="New Inventory Count" onClick={toggleAddModal}>
                <FaPlus />
              </button>
            </h4>
          </div>
          <div className="card-body">
            <Table data={inventories} columns={COLUMNS} defaultSortBy={defaultSortBy} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default Inventory;
