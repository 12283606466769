import { useEffect, useState, useRef } from "react";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { Toast } from "bootstrap";
import { FaRegEdit, FaPlus, FaList } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const RecipeItems = ({
  recipe_id,
  isRecipeItemUpdated,
  edit_RecipeItem,
  delete_RecipeItem,
}) => {
  const [hasError, handleHttpRequest] = useHttp();
  const [recipeItems, setRecipeItems] = useState([]);

  const [beUpdated, setBEUpdated] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);

  const notificationStatus = useRef();
  const notificationMessage = useRef();

  const toastRef = useRef();
  var [toast, setToast] = useState(false);

  // useEffect(() => {
  //     var myToast = toastRef.current
  //     var bsToast = Toast.getInstance(myToast)
  //     console.log(bsToast);
  //     if (!bsToast) {
  //         // initialize Toast
  //         bsToast = new Toast(myToast,{delay: 4000, autohide: true})
  //         // hide after init
  //         bsToast.hide()
  //         setToast(false)
  //         console.log("Hide after 4000",toast);
  //     }else {
  //         // toggle
  //         toast ? bsToast.show() : bsToast.hide()
  //         console.log("If toast Is true.. show. Toast is ",bsToast);
  //         // setToast(state => !state);
  //     }
  // },[toastUpdated])

  const getRecipes = async () => {
    // const response = await handleHttpRequest('recipeitem/recipe/'+recipe_id);
    const response = await handleHttpRequest({
      page: "recipeitem/recipe/" + recipe_id,
      method: "GET",
    });
    if (!hasError) {
      setRecipeItems(response);
    }
  };

  useEffect(() => {
    getRecipes();
  }, [beUpdated, isRecipeItemUpdated]);

  const COLUMNS = [
    {
      Header: "Ingredient",
      accessor: "ingredient.name",
    },
    {
      Header: "Quantity",
      accessor: "ingredient_quantity",
      // Cell: ({value}) => formatPhoneNumber(value)
    },
    {
      Header: "Cost per Unit",
      accessor: "cost_per_unit",
      Cell: ({ value }) => "$" + value,
    },
    {
      Header: "Recipe Cost",
      accessor: "recipe_cost",
      Cell: ({ value }) =>
        value !== undefined && !isNaN(value)
          ? `$${Number(value).toFixed(2)}`
          : "$0.00", // Fallback if value is invalid or undefined
    },
    
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            onClick={() => edit_RecipeItem(cell.value)}
          >
            <FaRegEdit className="themecolor" />
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              delete_RecipeItem(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  const recipeId = useRef(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };
  const saveRecipe = (response) => {
    setIsAddModalOpen(false);
    // notificationMessage.current = response;
    // notificationStatus.current = 200;
    // setToast(true);
    // setToastUpdated(state => !state);
    // setBEUpdated(state =>!state);
  };

  return (
    <>
      {/* {isAddModalOpen && 
            <AddRecipe saveClick={saveRecipe} onClick={openAddModal}/>
            //recipe_id={recipeId}
            } */}
      {/* {isdeleteModalOpen && <DeleteModal deleteId={deleteIdIs.current} onClick={openDeleteModal} onDelete={deleteHandler} item="recipe"/>} */}
      <div className="">
        <div className="mt-3">
          <Table data={recipeItems} columns={COLUMNS} />
        </div>
      </div>
    </>
  );
};

export default RecipeItems;
