import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import QuickAddInventory from "./QuickAddInventory";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { FaRegEdit, FaPlus, FaSave, FaMinus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { getDataFromStore } from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const STORE_NAME = 'inventory-list';
const DB_NAME ='inventory-db';
const ingredientDB = 'ingredientDB';
const ingredienStore = 'ingredienStore';
const InventoryCount = () => {
  const { id: inventory_id } = useParams();
  const [_, handleHttpRequest] = useHttp();
  const isOnline = useNetworkStatus();
  const [inventories, setInventories] = useState([]);
  const [initialValue, setInitialValue] = useState({
    date_time: "",
    location: "",
  });

  const getInventory = async () => {
    if (!isOnline) {
      // Fetch data from IndexedDB when offline
      const offlineData = (await getDataFromStore(DB_NAME, STORE_NAME)).filter(inventory => inventory.id === inventory_id);
      if (offlineData.length) {
        const response = offlineData[0];
        setInitialValue({
          date_time: response.date_time,
          location: response.storagelocation.name,
        });
       await getIngredientsLocations(response.storagelocation.id); // Fetch ingredients data
      }
    } else {
      // Fetch from API when online
      const response = await handleHttpRequest({
        page: `inventory-count/${inventory_id}`,
        method: "GET",
      });
      if (!response.status) {
        setInitialValue({
          date_time: response.date_time,
          location: response.storagelocation.name,
        });
        getIngredientsLocations(response.storage_location_id);
      } else {
        // Handle error case
        toast.error(response.message || "Failed to fetch inventory.");
      }
    }
  };

  const getIngredientsLocations = async (location_id) => {
    if (!isOnline) {
      // Fetch ingredients data from IndexedDB when offline
      const offlineData = (await getDataFromStore(ingredientDB, ingredienStore)).filter(location => location.id === location_id);
      console.log("====offline ingridient by location"+offlineData);
      if (offlineData.length) {
        const response = offlineData;
        setInventories(response || []);
      } else {
        toast.error("No ingredients found offline.");
      }
    } else {
      // Fetch ingredients from API when online
      const response = await handleHttpRequest({
        page: `ingredients-by-location/${location_id}`,
        method: "GET",
      });
      if (!response.status) {
        setInventories(response || []);
      } else {
        toast.error(response.message || "Failed to fetch ingredients.");
      }
    }
  };

  useEffect(() => {
    getInventory();
  }, [isOnline]);

  const incrementQty = (value) => {
    return value == undefined ? 1 : value + 1;
  };

  const decrementQty = (value) => {
    return value > 0 ? value - 1 : 0;
  };

  const COLUMNS = [
    {
      Header: "Location",
      accessor: "storagelocation.name",
    },
    {
      Header: "Ingredient",
      accessor: "ingredient.name",
    },
    {
      Header: "Size On-Hand",
      accessor: "size_on_hand",
      Cell: (cell) => cell.row.original.ingredient.default_unit_measure,
    },
    {
      Header: "Qty On-Hand",
      accessor: "qty_on_hand",
      Cell: ({ value, row }) => (
        <>
          <button onClick={() => row.original.qty_on_hand = decrementQty(value)}>
            <FaMinus />
          </button>
          <span>{value == undefined ? "" : value}</span>
          <button onClick={() => row.original.qty_on_hand = incrementQty(value)}>
            <FaPlus />
          </button>
        </>
      ),
    },
    {
      Header: "Qty in Dflt Unit",
      accessor: "qty_in_dflt_unit",
      Cell: (cell) =>
        (cell.row.original.qty_on_hand == undefined
          ? 0
          : cell.row.original.qty_on_hand) +
        " " +
        cell.row.original.ingredient.default_unit_measure,
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-recipe/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-recipe/${cell.value}`} className="themecolor">
              <MdOutlineDoNotDisturbAlt />{" "}
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              // Handle delete action
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };

  const saveInventoryCount = (message, status) => {
    setIsAddModalOpen(false);
    status !== 200 ? toast.error(message) : toast.info(message);
  };

  return (
    <>
      <MainPanel>
        {/* {isAddModalOpen && (
          <AddNewInventoryCount
            saveClick={saveInventoryCount}
            onClick={openAddModal}
          />
        )} */}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">Inventory Count</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row">
              <div className="col-sm-3">{initialValue.date_time}</div>
              <div className="col-sm-3">{initialValue.location}</div>
              <div className="col-sm-3">
                <button className="btn btn-link btn-just-icon icontheme editcount">
                  <FaSave className="themecolor" />
                </button>
                <button className="btn btn-link btn-just-icon icontheme editcount">
                  <RiDeleteBin6Line className="themecolor" />
                </button>
              </div>
            </div>
            <div className="row form-check form-switch">
              <input
                className="form-check-input ckbtheme"
                type="checkbox"
                role="switch"
                id="ingredient_by_location"
              />
              <label className="form-check-label" htmlFor="ingredient_by_location">
                Filter Ingredient based on Location assignments
              </label>
            </div>
            <QuickAddInventory />
            <hr />
            <Table data={inventories} columns={COLUMNS} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default InventoryCount;
