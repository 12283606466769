import { FaSave } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import units from "../../utils/units";
import { useState, useEffect } from "react";
import CustomMeasurements from "../CustomMeasurements";
import SelectIngredients from "../ingredients/SelectIngredients";
import SelectIngredientsByLocation from "./SelectIngredientsByLocation";

const QuickAddInventory = ({
  ingredientsForLocation,
  addIngredienttoInventory,
}) => {
  const [ingredient, setIngredient] = useState();
  const updateIngredient = (value) => {
    setIngredient(value);
  };

  // Initially, set unitMeasure to empty. We will only update when a user selects a unit.
  const [unitMeasure, setUnitMeasure] = useState(null);

  // Update unit measure and formik value when changing unit
  const updateUnitMeasure = (value) => {
    let unit_measure = units.find((unit) => unit.unit === value);
    setUnitMeasure(unit_measure);
    formik.setFieldValue("size_on_hand", unit_measure.unit); // Sync with formik's value for size_on_hand
  };

  const formik = useFormik({
    initialValues: {
      ingredient_id: "",
      size_on_hand: "", // Initially empty, until a unit is selected
      qty_on_hand: "",
    },
    validationSchema: Yup.object({
      ingredient_id: Yup.string().required(" Ingredient is required."),
      size_on_hand: Yup.string().required(
        "Select a size on hand for the Ingredient."
      ),
      qty_on_hand: Yup.number()
      .typeError("Quantity must be a number")
      .required("Quantity is required.")
      .min(1, "Quantity must be greater than zero."),
    }),
    onSubmit: (values) => {
      addIngredienttoInventory({
        ingredient: ingredient,
        ingredient_id: values.ingredient_id,
        size_on_hand: values.size_on_hand,
        qty_on_hand: values.qty_on_hand,
      });
      formik.resetForm();
      setUnitMeasure(null); // Reset unitMeasure after form reset
    },
  });

  const [useIngLocationAssignment, setUseIngLocationAssignment] =
    useState(true);
  const setUseIngLocationAssignmentHandler = () => {
    setUseIngLocationAssignment((state) => !state);
  };

  return (
    <>
      <div className="row form-check form-switch">
        <input
          className="form-check-input ckbtheme"
          type="checkbox"
          role="switch"
          id="ingredient_by_location"
          checked={useIngLocationAssignment}
          onChange={setUseIngLocationAssignmentHandler}
        />
        <label className="form-check-label" htmlFor="ingredient_by_location">
          Filter Ingredient based on Location assignments
        </label>
      </div>
      <div className="quickadd my-4">
        <form onSubmit={formik.handleSubmit}>
          <small className="texttheme fw-bold text-uppercase">
            QUICK ADD - INVENTORY COUNT
          </small>
          <div className="row">
            <div className="col-lg-3 col-sm-4">
              <fieldset>
                <label htmlFor="ingredient_id" className="lblrequired lbltheme">
                  Ingredient*
                </label>
                {useIngLocationAssignment ? (
                  <SelectIngredientsByLocation
                    setIngredient={updateIngredient}
                    ingredientsForLocation={ingredientsForLocation}
                    value={formik.values.ingredient_id}
                    setUnitMeasure={updateUnitMeasure}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <SelectIngredients
                    setIngredient={updateIngredient}
                    value={formik.values.ingredient_id}
                    setUnitMeasure={updateUnitMeasure}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
              </fieldset>
            </div>
            <div className="col-lg-2 col-sm-3">
              <fieldset>
                <label htmlFor="size_on_hand" className="lblrequired lbltheme">
                  Size*
                </label>
                <select
                  className="form-select"
                  name="size_on_hand"
                  id="size_on_hand"
                  value={formik.values.size_on_hand}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select a size</option> {/* Empty option for initial state */}
                  {unitMeasure && (
                    <option key={unitMeasure.id} value={unitMeasure.unit}>
                      {unitMeasure.unit}
                    </option>
                  )}
                </select>
              </fieldset>
            </div>
            <div className="col-lg-2 col-sm-3">
              <fieldset>
                <label htmlFor="qty_on_hand" className="lblrequired lbltheme">
                  Add Count*
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="qty_on_hand"
                  name="qty_on_hand"
                  value={formik.values.qty_on_hand}
                  onChange={formik.handleChange}
                />
              </fieldset>
            </div>
            <div className="col d-flex align-self-end justify-content-start">
              <button
                type="submit"
                className="btn btn-link btn-just-icon"
                id="QuickAddInventory"
                name="QuickAddInventory"
                title="Add"
              >
                <FaSave className="save-icon" />
              </button>
            </div>
          </div>
        </form>
        <div className="row">
          <div id="qadd-ingred-errmsg" className="error-msg">
            {formik.errors.ingredient_id
              ? formik.errors.ingredient_id
              : formik.errors.size_on_hand
              ? formik.errors.size_on_hand
              : formik.errors.qty_on_hand
              ? formik.errors.qty_on_hand
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickAddInventory;
