import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import useHttp from "../hooks/useHttp";
import style from "../assets/style/modal.module.css";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

const UploadSaleHistoryModal = ({ onClose, onUpload, onError }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("auth_token");
  const [errorMessage, setErrorMessage] = useState("");

  const uploadSaleHistory = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(apiUrl + "/sales/upload-csv", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        onUpload(response.data);
      })
      .catch((error) => {
        error.response.data.errors &&
          error.response.data.errors.map((element) =>
            onError(element.errors[0])
          );
        error.response.data.message && onError(error.response.data.message);
      });
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]} w-75`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Upload Sales History
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <Importer
                  dataHandler={async (rows) => {
                    // required, receives a list of parsed objects based on defined fields and user column mapping;
                    // may be called several times if file is large
                    // (if this callback returns a promise, the widget will wait for it before parsing more data)
                    // console.log("received batch of rows", rows);

                    // mock timeout to simulate processing
                    await new Promise((resolve) => setTimeout(resolve, 500));
                  }}
                  chunkSize={10000} // optional, internal parsing chunk size in bytes
                  defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                  restartable={false} // optional, lets user choose to upload another file when import is complete
                  onStart={({ file, fields }) => {
                    // optional, invoked when user has mapped columns and started import
                    // console.log("starting import of file",file,"with fields",fields);
                    uploadSaleHistory(file);
                  }}
                  onComplete={({ file, fields }) => {
                    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                    // console.log( "finished import of file",file,"with fields",fields);
                  }}
                  // onClose={() => {
                  //   // optional, invoked when import is done and user clicked "Finish"
                  //   // (if this is not specified, the widget lets the user upload another file)

                  //   console.log("importer dismissed");
                  // }}
                >
                  <ImporterField name="menu_item" label="Menu Item" />
                  <ImporterField name="item_qty" label="Item Qty" optional />
                </Importer>
              </div>
              <div
                className={`${style["modal-footer"]} justify-content-between`}
              >
                <div className="d-flex">
                  {errorMessage != "" && (
                    <span className="error-msg"> {errorMessage}</span>
                  )}
                </div>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={uploadSaleHistory}
                >
                  Upload
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomImporter = (props) => {
  return ReactDOM.createPortal(
    <UploadSaleHistoryModal
      onClose={props.onClose}
      onUpload={props.onUpload}
      onError={props.onError}
    />,
    document.getElementById("modal")
  );
};

export default CustomImporter;
