import React, { useState, useEffect, useRef } from "react";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import ButtonGroup from "../../components/ButtonGroup";
import DeleteSupplierIngredient from "./DeleteSupplierIngredient";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import SupplierIngredientsTable from "./SupplierIngredientsTable";

const SupplierIngredients = ({
  ingUnit,
  supplierIngSaved,
  ingId,
  onSupplierIngOpen,
  updateBD,
  onSupplierIngEditOpen,
  onSuppIngEditSave,
}) => {
  /* Get Supplier Ingredients */
  const [supplierngredients, setSupplierIngredients] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSupplierSet, setIsSupplierSet] = useState(false);

  const getSupplierIngredients = async () => {
    const response = await handleHttpRequest({
      page: "supplieringredient/ingredient/" + ingId,
      method: "GET",
    });
    // console.log("SuppIng- ",response.message);
    if (!hasError) {
      setSupplierIngredients(response.message);
    }
  };

  useEffect(() => {
    getSupplierIngredients();
  }, [supplierIngSaved, isDeleted, isSupplierSet]); //[beUpdated,setBEUpdated])

  const deleteSuppIngHandler = () => {
    setIsDeleted(true);
    updateBD();
  };
  const setSupplierHandler = () => {
    setIsSupplierSet((state) => !state);
    updateBD();
  };

  // /* Delete Ingredient Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };

  return (
    <div id="supplieringredssec" name="supplieringredssec">
      <div class="row pb-4">
        <div class="col-sm-4">
          <button
            type="button"
            className="btn btntheme btn-sm"
            id="AddSuppIngredPop"
            name="AddSuppIngredPop"
            title="Supplier Option for Ingredient"
            data-btnFunc="add"
            onClick={onSupplierIngOpen}
          >
            <FaPlus /> SUPPLIER OPTION FOR INGREDIENT
          </button>
        </div>
      </div>
      <SupplierIngredientsTable
        column={[
          "Supplier Name",
          "Item Number",
          "Item Description",
          "Sells By",
          "Packs",
          "Unit size",
          "Price Per sells By",
          "Price Per " + ingUnit,
          "Actions",
        ]}
        table_values={supplierngredients}
        onDelete={deleteSuppIngHandler}
        onSupplierSet={setSupplierHandler}
        onSupplierIngEditOpen={onSupplierIngEditOpen}
        onSuppIngEditSave={onSuppIngEditSave}
      />
    </div>
  );
};

export default SupplierIngredients;
