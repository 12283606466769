import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import MainPanel from "../../components/MainPanel";
import AddRecipeItem from "./AddRecipeItem";
import RecipeItems from "./RecipeItems";
import EditRecipeItem from "./EditRecipeItem";
import DeleteModal from "../DeleteModal";
import Notification from "../../components/Notification";
import { Toast } from "bootstrap";
import { IoIosSave } from "react-icons/io";

const EditRecipe = () => {
  const { id: recipe_id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    yeild_qty: "",
    yeild_measure: "",
  });
  const [recipeCost, setRecipeCost] = useState(0);
  const [recipeCostPer, setRecipeCostPer] = useState(0);
  const [hasError, handleHttpRequest] = useHttp();
  const [isEditRecipe, setIsEditRecipe] = useState(false);

  const [beUpdated, setBEUpdated] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);

  const notificationStatus = useRef();
  const notificationMessage = useRef();

  const toastRef = useRef();
  var [toast, setToast] = useState(false);

  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { delay: 4000, autohide: true });
      // hide after init
      bsToast.hide();
      setToast(false);
    } else {
      // toggle
      toast ? bsToast.show() : bsToast.hide();
      // setToast(state => !state);
    }
  }, [toastUpdated, setToastUpdated]);

  const editRecipe = async (values) => {
    // const response = await handleHttpRequest(
    //   "recipe/edit/" + recipe_id,
    //   "PUT",
    //   values
    // );
    const response = await handleHttpRequest({
      page: "recipe/edit/" + recipe_id,
      method: "PUT",
      data: values,
    });
    if (!hasError) {
      setIsEditRecipe((state) => !state);
      // updateClick({"status":response.status, "message":response.message});
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Recipe Name is required.")
        .max(100, "Recipe name is too long"),
      yeild_qty: Yup.number()
        .typeError("Yeild quantity must be a number")
        .required("Enter the yeild quantity"),
      yeild_measure: Yup.string().required(
        "Select a Measurement for the Recipe."
      ),
    }),
    onSubmit: (values) => {
      editRecipe(values);
      formik.resetForm();
    },
  });

  /** Recipe Item */
  const [isRecipeItemUpdated, setIsRecipeItemUpdated] = useState(false);
  const [isEditRecipeItem, setIsEditRecipeItem] = useState(false);
  const recipeItem = useRef(0);
  const openEditRecipeItem = (recipeItem_id) => {
    recipeItem.current = recipeItem_id;
    setIsEditRecipeItem(true);
  };
  const closeEditRecipeItem = () => {
    setIsEditRecipeItem((state) => !state);
  };
  const saveEditRecipeItem = (response) => {
    setIsEditRecipeItem((state) => !state);
    //notify response
    notificationMessage.current = response;
    notificationStatus.current = 200;
    setToast(true);
    setToastUpdated((state) => !state);
    //refresh recipe items list
    setIsRecipeItemUpdated((state) => !state);
  };

  const handleRecipeItemUpdate = (response) => {
    //refresh recipe items list
    setIsRecipeItemUpdated((state) => !state);

    notificationMessage.current = response;
    notificationStatus.current = 200;
    setToast(true);
    setToastUpdated((state) => !state);
  };

  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    notificationMessage.current = data[0];
    notificationStatus.current = data[1];
    setToast(true);
    setToastUpdated((state) => !state);
    setIsRecipeItemUpdated((state) => !state);
  };

  const getRecipe = async (id) => {
    // const response = await handleHttpRequest("recipe/" + id);
    const response = await handleHttpRequest({
      page: "recipe/" + id,
      method: "GET",
    });
    if (!hasError) {
      setInitialValues({
        name: response.name,
        yeild_qty: response.yeild_qty,
        yeild_measure: response.yeild_measure,
      });
      setRecipeCost(response.recipe_cost);
      setRecipeCostPer(response.recipe_cost_per);
    }
  };

  useEffect(() => {
    getRecipe(recipe_id);
  }, [isEditRecipe, isRecipeItemUpdated]);

  return (
    <>
      <MainPanel>
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="recipeitem"
            name="recipe item"
          />
        )}
        {isEditRecipeItem && (
          <EditRecipeItem
            recipe_id={recipe_id}
            recipeItem_id={recipeItem.current}
            onClick={closeEditRecipeItem}
            onSave={saveEditRecipeItem}
            recipe_name={initialValues.name}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ textTransform: "uppercase" }}
              >
                {initialValues.name} recipe
              </h5>
            </div>
          </div>
          <div className="">
            <div className="modal-body row g-3" style={{ padding: "1em 2em" }}>
              <form className="" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <fieldset>
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Recipe Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-2 col-sm-3 mx-lg-4">
                    <div className="fw-bold">Recipe Cost</div>
                    <h3 className="themecolor">
  ${!isNaN(recipeCost) && recipeCost !== undefined ? Number(recipeCost).toFixed(2) : "0.00"}
</h3>

                  </div>
                  <div className="col-lg-2 col-sm-3">
                    <div className="fw-bold">
                      Recipe Cost Per {initialValues.yeild_measure}
                    </div>
                    <h3 className="themecolor">
  ${!isNaN(recipeCostPer) && recipeCostPer !== undefined ? Number(recipeCostPer).toFixed(2) : "0.00"}
</h3>

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <fieldset>
                      <label
                        htmlFor="ingred-dfltunitmeas"
                        className="lblrequired lbltheme"
                      >
                        Yield Measurement*
                      </label>
                      <select
                        className="form-select"
                        name="yeild_measure"
                        id="yeild_measure"
                        value={formik.values.yeild_measure}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option selected>Select Measurement</option>
                        {units.map((unit) => (
                          <option key={unit.id} value={unit.unit}>
                            {unit.name}
                          </option>
                        ))}
                      </select>
                    </fieldset>
                  </div>
                  <div className="col-sm-3">
                    <fieldset>
                      <label htmlFor="yeild_qty" class="lblrequired lbltheme">
                        Yield Quantity
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="yeild_qty"
                        name="yeild_qty"
                        value={formik.values.yeild_qty}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col d-flex align-self-end justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  <div id="qadd-ingred-errmsg">
                    {formik.errors.name
                      ? formik.errors.name
                      : formik.errors.default_unit_measure
                      ? formik.errors.default_unit_measure
                      : formik.errors.default_unit_price
                      ? formik.errors.default_unit_price
                      : formik.errors.category_id
                      ? formik.errors.category_id
                      : null}
                  </div>
                </div>
              </form>
              <hr></hr>
              <div>
                <AddRecipeItem
                  recipe_id={recipe_id}
                  saveClick={handleRecipeItemUpdate}
                />
              </div>
              <div>
                <RecipeItems
                  recipe_id={recipe_id}
                  edit_RecipeItem={openEditRecipeItem}
                  delete_RecipeItem={openDeleteModal}
                  isRecipeItemUpdated={isRecipeItemUpdated}
                />
              </div>
            </div>
          </div>
        </div>
        <Notification
          toastRef={toastRef}
          toast={toast}
          setToast={setToast}
          notificationStatus={notificationStatus.current}
        >
          {notificationMessage.current}
        </Notification>
      </MainPanel>
    </>
  );
};

export default EditRecipe;
