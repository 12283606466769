import React, { useState, useEffect, useMemo, useCallback } from "react";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import { Search } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import RecieveOrdersButton from "../../components/groupButtons/RecieveOrdersButtonG";
import TableModal from "../../components/TableModal";

const OrderRow = ({ item, index, onCheckboxChange, onInputChange, onSendToCart, onVoidRemaining,order_status,onRowClick  }) => {
  const handleInput = (field, value) => {
    const sanitizedValue =
      field === "receiveQty"
        ? Math.min(item.remainingQty, Math.max(0, parseInt(value) || 0))
        : parseFloat(value) || 0;
    onInputChange(index, field, sanitizedValue);
  };

  return (
    <tr onClick={() => onRowClick(item)} style={{ cursor: "pointer" }}><td>{item.itemNumber}</td>
      <td>{item.description}</td>
      <td>{item.price_opt_measure == null ? item.packs : ""}</td>
      <td>{item.price_opt_measure == null ? item.unitsize : ""}</td>
      <td>${Number(item.priceperItem).toFixed(2)}</td>
      <td>{item.uom}</td>
     <td>${Number(item.unitPrice).toFixed(2)}</td>
      <td>
        <input
          type="number"
          className="form-control"
          value={item.supplierPrice}
          onChange={(e) => handleInput("supplierPrice", e.target.value)}
          min="0"
          disabled={item.remainingQty <= 0 || order_status !== "Not recvd"}
        />
      </td>
      <td>{item.orderedQty}</td>
      <td>
        <input
          type="number"
          className="form-control"
          value={item.receiveQty}
          onChange={(e) => handleInput("receiveQty", e.target.value)}
          min="0"
          disabled={item.remainingQty <= 0 || order_status !== "Not recvd"}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={item.receiveAll}
          disabled={order_status !== "Not recvd"}
          onChange={() => onCheckboxChange(index)}
        />
      </td>
      <td>
      <RecieveOrdersButton 
          sendToCart={onSendToCart} 
          voidRemaining={onVoidRemaining}
          disabled={item.remainingQty <= 0 || order_status === "Not recvd" } 
          id={item.id} 
        />
      </td>
    </tr>
  );
};

const ReceivingOrder = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [blankpage, setBlankpage] = useState(true);
  const [order_id, setOrderid] = useState();
  const [order_status, setOrderStatus] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [orderDetails, setOrderDetails] = useState({
    purchaseOrderNumber: "",
    supplierName: "",
  });
  
  // const lineItem = useRef({ current: null, remaining_qty: null });
  const [receivingDate, setReceivingDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [_, handleHttpRequest] = useHttp();
  const [showDropdown, setShowDropdown] = useState(false);
// Modal state for TableModal
const [showModal, setShowModal] = useState(false);
const [selectedRow, setSelectedRow] = useState(null);
const openModal = (row) => {
  setSelectedRow(row);
  setShowModal(true);
};

const closeModal = () => {
  setShowModal(false);
  setSelectedRow(null);
};
  const getPurchaseOrders = useCallback(async () => {
    try {
      const response = await handleHttpRequest({ page: "purchaseorder", method: "GET" });
      if (!response.state) {
        const filteredOrders = response.filter(order => order.order_status === "Not recvd");
        console.log(filteredOrders);
        setPurchaseOrders(filteredOrders);
        setFilteredPurchaseOrders(filteredOrders);
      }
    } catch (error) {
      console.error("Error fetching purchase orders:", error);
    }
  }, [handleHttpRequest]);

  useEffect(() => {
    getPurchaseOrders();
  }, []);
const fetchData = async (purchaseOrderNumber) => {
    setLoading(true);
    
    try {
      const response = await handleHttpRequest({
        page: `purchaseorderitem-order/${purchaseOrderNumber}`,
        method: "GET",
      });
  
      const { purchaseOrderItems = [], purchaseOrder = {}, order_status = "" } = response;
      const { supplier = {} } = purchaseOrder;
  
      const formattedData = purchaseOrderItems.map((item, index) => {
        const { supplieringredient = {} } = item;
        const {
          ingredient_id=null,
          supplier_item_number = "",
          supplier_item_desc = "N/A",
          sold_by = "N/A",
          packs_per_sold_by = null,
          pack_unit_size = null,
          pack_unit_measure =null,
          price_opt_measure=null,
          price_per_item = null,
          price_per_sold_by = "",
        } = supplieringredient;
  
        return {
          id: item.id || index + 1,
          ingredientid:ingredient_id,
          description: supplier_item_desc,
          itemNumber: supplier_item_number,
          uom: sold_by,
          packs: packs_per_sold_by ? packs_per_sold_by : "", // Blank if 0, null, or undefined
          price_opt_measure:price_opt_measure,
          unitsize: (pack_unit_size && pack_unit_measure) ? `${pack_unit_size} ${pack_unit_measure}` : "", // Blank if 0, null, or undefined
          priceperItem: price_per_item ? price_per_item : "", // Blank if 0, null, or undefined
          unitPrice: price_per_sold_by ? parseFloat(price_per_sold_by) : "", // Blank if 0, null, or undefined
          supplierPrice: price_per_sold_by ? parseFloat(price_per_sold_by) : "", // Blank if 0, null, or undefined
          orderedQty: parseInt(item.ordered_qty, 10) || 0,
          receivedQty: parseInt(item.received_qty, 10) || 0,
          receiveQty: parseInt(item.remaining_qty, 10) || 0,
          remainingQty: parseInt(item.remaining_qty, 10) || 0,
          receiveAll: true,
          status: item.order_status || "Not recvd",
        };
      });
  
      setData(formattedData);
      setOrderDetails({
        purchaseOrderNumber: purchaseOrder.purchase_order_number,
        supplierName: supplier.name,
        status: order_status,
      });
  
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredOrders = useMemo(() => {
    if (searchTerm) {
      return purchaseOrders.filter((order) =>
        order.purchase_order_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return []; // Return all orders if no search term is provided
  }, [searchTerm, purchaseOrders]);
  

  const handlePurchaseOrderSelect = (order_id,order_status) => {
    setOrderid(order_id);
    setOrderStatus(order_status);
    fetchData(order_id);
    setSearchTerm("");
    setShowDropdown(false);
    setBlankpage(false);
  };

  const handleConfirmReceipt = async () => {
    try {
      const promises = data.map(async (item) => {
        if (item.receiveQty > 0) {
          const values = {
            id: null,
            receive_order_qty: item.receiveQty,
            receive_price: item.supplierPrice,
            receive_date: receivingDate,
            receipt_number: receiptNumber || "NA",
            purchase_order_item_id: item.id,
          };

          const response = await handleHttpRequest({
            page: "receiveorder",
            method: "POST",
            data: values,
          });

          if (response.status) {
            toast.error(`Failed to confirm receipt for item ${item.id}`);
            return;
          }
        }
      });

      await Promise.all(promises);
      toast.success("Receipt confirmed successfully!");
      handlePurchaseOrderSelect(order_id,"recvd");
    } catch (error) {
      console.error("Error confirming receipt:", error);
      toast.error("Failed to confirm receipt. Please try again.");
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to cancel?")) {
      setData([]);
      setOrderDetails({ purchaseOrderNumber: "", supplierName: "" });
      setBlankpage(true);
    }
  };

  const sendItemsToCart = async (purchaseOrderItemId) => {
    const response = await handleHttpRequest({
      page: `purchaseorderitem-sendtocart/${purchaseOrderItemId}`,
      method: "GET",
    });
    if (response && !response.status) {
      toast.info("Sending item to cart...");
      handlePurchaseOrderSelect(order_id,"recvd");
    } else {
      toast.error(response?.data[0] || "Failed to send item to cart");
    }
  };

  const voidRemaining = async (purchaseOrderItemId) => {
    await new Promise((resolve) => {
      alert("Void remaining unreceived items?");
      resolve();
    });
    const response = await handleHttpRequest({
      page: `receiveorder-void/${purchaseOrderItemId}`,
      method: "PUT",
    });
    if (response && !response.status) {
      toast.info("Remaining items voided.");
      handlePurchaseOrderSelect(order_id,"recvd");
    } else {
      toast.error(response?.data[0] || "Failed to void remaining items");
    }
  };

  return (
    <MainPanel>
      <div className="card">
        <div className="card-header bg-secondary text-white d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Receiving Order</h4>
        </div>
        <div className="card-body">
          {/* Search Bar */}
          <div className="d-flex justify-content-center mb-4">
            <div className="input-group search-bar-container">
              <input
                type="text"
                className="form-control"
                placeholder="Search purchase orders..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                aria-label="Search Purchase Orders"
              />
              <button className="btn btn-outline-secondary" aria-label="Search">
                <Search />
              </button>
              {filteredOrders.length > 0 && (
                <ul className="dropdown-menu show">
                  {filteredOrders.map((order) => (
                    <li key={order.id}>
                      <button
                        className="dropdown-item"
                        onClick={() => handlePurchaseOrderSelect(order.id,order.order_status)}
                      >
                        <strong>{order.purchase_order_number}</strong>
                        <br />
                        {order.supplier.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {blankpage ? (
            <div className="row" style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className="col-md-12 text-center">Type in the Search bar to receive orders</div>
            </div>
          ) : (
            <>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                  <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
                  <span className="ml-2">Loading...</span>
                </div>
              ) : (
                <>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label htmlFor="purchaseOrderNumber">Purchase Order Number</label>
                      <input
                        id="purchaseOrderNumber"
                        type="text"
                        className="form-control"
                        value={orderDetails.purchaseOrderNumber}
                        readOnly
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="supplierName">Supplier Name</label>
                      <input
                        id="supplierName"
                        type="text"
                        className="form-control"
                        value={orderDetails.supplierName}
                        readOnly
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="receiptNumber">Receipt Number</label>
                      <input
                        id="receiptNumber"
                        type="text"
                        className="form-control"
                        placeholder="Enter receipt number"
                        value={receiptNumber}
                        onChange={(e) => setReceiptNumber(e.target.value)}
                        disabled={order_status !=="Not recvd" }
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="receivingDate">Receiving Date</label>
                      <input
                        id="receivingDate"
                        type="date"
                        className="form-control"
                        value={receivingDate}
                        disabled={order_status !== "Not recvd" }
                        onChange={(e) => setReceivingDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th> Item No</th>
                        <th>Description</th>
                        <th>Packs</th>
                        <th>Unit Size</th>
                        <th>Price per Item</th>
                        <th>UOM</th>
                        <th>Unit Price</th>
                        <th>Supplier Price</th>
                        <th>Ordered Qty</th>
                        <th>Receive Qty</th>
                        <th>Receive All</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <OrderRow
                          key={item.id}
                          item={item}
                          index={index}
                          onCheckboxChange={(index) => {
                            const updatedData = [...data];
                            updatedData[index].receiveAll = !updatedData[index].receiveAll;
                            updatedData[index].receiveQty = updatedData[index].receiveAll ? updatedData[index].remainingQty : 0;
                            setData(updatedData);
                          }}
                          onInputChange={(index, field, value) => {
                            const updatedData = [...data];
                            updatedData[index][field] = value;
                            setData(updatedData);
                          }}
                          onSendToCart={sendItemsToCart}
                          onVoidRemaining={voidRemaining}
                          order_status={order_status}
                          onRowClick={openModal}

                        />
                      ))}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={handleConfirmReceipt}   
                    disabled={ !data.some((item) => item.receiveQty > 0) || order_status !== "Not recvd"}>Confirm Receipt</button>
                    <button className="btn btn-danger ml-2" onClick={handleCancel}>Cancel</button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {showModal && (
        <TableModal
          id={selectedRow.ingredientid}
          onClose={closeModal}         
        ></TableModal>
      )}
      <ToastContainer position="bottom-right" hideProgressBar />
    </MainPanel>
  );
};

export default ReceivingOrder;

     
