import { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const MenuItemRecipeIngredient = ({
  menuitem_id,
  isRecipeIngUpdated,
  edit_RecipeIngItem,
  delete_RecipeIngItem,
  setTotalCostIngRec,
}) => {
  const [_, handleHttpRequest] = useHttp();
  const [recIngMenuItems, setRecIngMenuItems] = useState([]);
  // const [beUpdated, setBEUpdated] = useState(false);

  const getMenuItemRecipesIngredients = async () => {
    const response = await handleHttpRequest({
      page: "menuitem/recipes-ingredients/" + menuitem_id,
      method: "GET",
    });
    if (!response.status) {
      setRecIngMenuItems(response.recipe_ing_list);
      setTotalCostIngRec(response.total_cost);
    }
  };

  useEffect(() => {
    getMenuItemRecipesIngredients();
  }, [isRecipeIngUpdated]); //beUpdated

  const COLUMNS = [
    {
      Header: "Recipe/Ingredient",
      accessor: "name", //
      Cell: (cell) =>
        cell.row.original.type == "Recipe"
          ? cell.row.original.recipe.name
          : cell.row.original.ingredient.name,
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      Cell: (cell) =>
        cell.row.original.quantity + " " + cell.row.original.unit_measure,
    },
    {
      Header: "Cost",
      accessor: "cost",
      Cell: ({ value }) =>
        value !== undefined && !isNaN(value)
          ? `$${Number(value).toFixed(2)}` // Format with $ and two decimal places
          : "",
    },
    
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            onClick={() => {
              edit_RecipeIngItem(cell.value, cell.row.original.type);
            }}
          >
            <FaRegEdit className="themecolor" />
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              delete_RecipeIngItem(cell.value, cell.row.original.type);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="">
        <div className="mt-3">
          <Table data={recIngMenuItems} columns={COLUMNS} />
        </div>
      </div>
    </>
  );
};

export default MenuItemRecipeIngredient;
