import { useState } from "react";
import { MdViewList } from "react-icons/md";
import { FaCartShopping } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

const ReceiveOrderButtonGroup = ({
  cell,
  editItem
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ontoggle = () => {
    setShowDropDown((state) => !state);
  };

  return (
    <div class="btn-group">
      <button
        type="button"
        className="btn btntheme"
        onClick={() => {
          editItem(cell.value);
        }}
      >
        {" "}
        <MdViewList /> Detail
      </button>
    </div>
  );
};
export default ReceiveOrderButtonGroup;
