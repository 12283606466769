import MainPanel from "../../components/MainPanel";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { FaRegEdit, FaPlus, FaFileImport } from "react-icons/fa";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import AddMenuItem from "./AddMenuItem";
import DeleteModal from "../DeleteModal";
import SetStatusModal from "../SetStatusModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImportMenuItems from "../imports/ImportMenuItems";

// New component for detail modal
const MenuItemDetail = ({ item, onClose }) => {
  if (!item) return null;

  return (
    <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{item.name} Details</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-4 fw-bold">Menu Group:</div>
              <div className="col-8">{item.menu_group}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 fw-bold">Price:</div>
              <div className="col-8">${item.price}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 fw-bold">Cost:</div>
              <div className="col-8">${item.menuitem_cost}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 fw-bold">Status:</div>
              <div className="col-8">
                <span className={`badge rounded-pill ${item.status ? "text-bg-primary" : "text-bg-danger"}`}>
                  {item.status === 1 ? "Active" : "In-active"}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MenuItems = () => {
  const [_, handleHttpRequest] = useHttp();
  const [isMenuItemUpdated, setIsMenuItemUpdated] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const deactivateId = useRef(0);
  const menuItemStatus = useRef();

  const getMenuItems = async () => {
    const response = await handleHttpRequest({
      page: "menuitems",
      method: "GET",
    });
    if (!response.status) {
      setMenuItems(response);
    } else {
      toast.error("Failed to fetch menu items.");
    }
  };

  useEffect(() => {
    getMenuItems();
  }, [isMenuItemUpdated]);

  const COLUMNS = [
    {
      Header: "Menu Group",
      accessor: "menu_group",
    },
    {
      Header: "Menu Item",
      accessor: "name",
    },
    {
      Header: "Sales Price",
      accessor: "price",
      align: "right",
      Cell: ({ value }) => (value !== undefined ? `$${Number(value).toFixed(2)}` : "0.00"),
    },
    {
      Header: "Cost $",
      accessor: "menuitem_cost",
      align: "right",
      Cell: ({ value }) => (value !== undefined ? `$${Number(value).toFixed(2)}` : "0.00"),
    },
    
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value }) => (
        <span
          className={`badge rounded-pill ${value ? "text-bg-primary" : "text-bg-danger"}`}
          style={{ color: "#000" }}
        >
          {value === 1 ? "Active" : "In-active"}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <div>
          <Link to={`/edit-menu-item/${value}`} className="btn btn-link btn-just-icon icontheme editcount" style={{ color: "#FF7300" }}>
            <FaRegEdit />
          </Link>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => openDeactivateModal(value, row.original.status)}
          >
            <MdOutlineDoNotDisturbAlt />
          </button>
          {/* <button
            className="btn btn-link btn-just-icon"
            onClick={() => handleRowClick(row.original)}
          >
            Details
          </button> */}
        </div>
      ),
    },
  ];

  const openAddModal = () => setIsAddModalOpen((prev) => !prev);

  const saveMenuItem = (message) => {
    setIsAddModalOpen(false);
    toast.info(message);
    setIsMenuItemUpdated((prev) => !prev);
  };

  const openDeactivateModal = (id, status) => {
    deactivateId.current = id;
    menuItemStatus.current = status;
    setIsDeactivateModalOpen((prev) => !prev);
  };

  const deactivateHandler = (response) => {
    setIsDeactivateModalOpen(false);
    response[1] === 200 ? toast.info(response[0]) : toast.error(response[0]);
    setIsMenuItemUpdated((prev) => !prev);
  };

  const toggleImport = () => setIsImportModalOpen((prev) => !prev);

  const handleRowClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      <MainPanel>
        {isImportModalOpen && <ImportMenuItems onClose={toggleImport} />}
        {isAddModalOpen && <AddMenuItem saveClick={saveMenuItem} onClick={openAddModal} />}
        {isDeactivateModalOpen && (
          <SetStatusModal
            currentStatus={menuItemStatus.current}
            itemId={deactivateId.current}
            onClick={openDeactivateModal}
            onToggle={deactivateHandler}
            item="menuitem"
            name="menu item"
          />
        )}
        {selectedItem && <MenuItemDetail item={selectedItem} onClose={() => setSelectedItem(null)} />}

        <div className="card">
          <div className="card-header text-bg-secondary">
            <h4 className="card-title">
              MENU ITEMS
              <button
                type="button"
                className="btn btn-outline-light ms-3"
                onClick={openAddModal}
              >
                <FaPlus />
              </button>
            </h4>
          </div>
          <div className="card-body">
            <Table data={menuItems} columns={COLUMNS} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default MenuItems;
