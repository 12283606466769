import React, { useState, useRef } from "react";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import QuickAddIngredients from "./QuickAddIngredients";
import AddCategory from "../categories/AddCategory";
import DeleteIngredient from "./DeleteIngredient";
import AddSupplierIngredient from "../supplierIngredients/AddSupplierIngredient";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddIngredient from "./AddIngredient";
import { useIngredients } from "../../hooks/useIngredients";
import EditSupplierIngredient from "../supplierIngredients/EditSupplierIngredient";
import { Link } from "react-router-dom";
import ImportExcel from "../imports/ImportExcel";
import { ToastContainer, toast } from "react-toastify";
import TableModal from "../../components/TableModal";

const Ingredients = () => {
  const { ingredients, refreshIngredients, setIngredients } = useIngredients();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [categorySaved, setCategorySaved] = useState(false);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddSupplierIngModalOpen, setIsAddSupplierIngModalOpen] = useState(false);
  const [isEditSupplierIngModalOpen, setIsEditSupplierIngModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  
  const deleteIdIs = useRef(0);
  const ingId = useRef(0);
  const supplierIngId = useRef(0);

  const handleCellEdit = (rowIndex, columnId, newValue) => {
    const updatedData = [...ingredients];
    updatedData[rowIndex][columnId] = newValue;
    setIngredients(updatedData);
  };

  const COLUMNS = [
    {
      Header: "Item Name",
      accessor: "name",
      width:"25%"
    },
    {
      Header: "Category",
      accessor: "category.name",
      width:"10%"
    },
    {
      Header: "UOM",
      accessor: "default_unit_measure",
      width:"10%",
      textAlign:"center",
      Cell: ({ value }) => {
        const uppercased = value ? value.toUpperCase() : "";
        return uppercased;
      }
    },
    {
      Header:"Last Price",
      accessor: "default_unit_price",
      width: "15%",
      textAlign:"right",
      Cell: ({ value }) => {
        const formattedValue = value != undefined ? `$${Number(value).toFixed(2)}` : "";
        return (
          <div style={{ textAlign: "right" }}>
            {formattedValue}
          </div>
        );
      }
    }
    
,    
    {
      Header: "Primary Supplier",
      accessor: "primary_supplier.name",
      width:"20%"
    },
    {
      Header: "Actions",
      accessor: "id",
      width:"15%",
      disableSortBy: true,
      Cell: ({ value }) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-ingredient/${value}`} className="themecolor">
              <FaRegEdit />
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => openDeleteModal(value)}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];
  const defaultSortBy = [
    {
      id: "name", // Column accessor
      desc: false, // Ascending order
    },
  ];

  const openAddModal = () => setIsAddModalOpen(prev => !prev);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen(prev => !prev);
  };
  
  const saveHandler = ({ status, message }) => {
    setIsAddModalOpen(false);
    status === 200 ? toast.success(message) : toast.error(message);
    refreshIngredients();
  };

  const quickAddIngredient = (data) => {
    data.status === 200 ? toast.success(data.message) : toast.error(data.message);
    refreshIngredients();
  };

  return (
    <MainPanel>
      {isImportModalOpen && (
        <ImportExcel
          onClose={() => setIsImportModalOpen(false)}
          onImport={() => {
            setIsImportModalOpen(false);
            refreshIngredients();
          }}
          onError={(message) => {
            setIsImportModalOpen(false);
            toast.error(message);
          }}
        />
      )}
      
      {isEditSupplierIngModalOpen && (
        <EditSupplierIngredient
          supplierIngId={supplierIngId.current}
          saveClick={() => {
            setIsEditSupplierIngModalOpen(false);
            refreshIngredients();
            toast.success("Supplier Ingredient updated");
          }}
          onClick={() => setIsEditSupplierIngModalOpen(false)}
        />
      )}

      {isAddSupplierIngModalOpen && (
        <AddSupplierIngredient
          ingredient_id={ingId}
          saveClick={() => {
            setIsAddSupplierIngModalOpen(false);
            refreshIngredients();
            toast.success("Supplier Ingredient created");
          }}
          onClick={() => setIsAddSupplierIngModalOpen(false)}
        />
      )}

      {isAddCategoryModalOpen && (
        <AddCategory
          saveClick={() => {
            setCategorySaved(prev => !prev);
            setIsAddCategoryModalOpen(false);
          }}
          onClick={() => setIsAddCategoryModalOpen(false)}
        />
      )}

      {isdeleteModalOpen && (
    <DeleteIngredient
    deleteId={deleteIdIs.current}
    onClick={() => setIsDeleteModalOpen(false)}
    onDelete={(data) => {
      setIsDeleteModalOpen(false);
  
      // Check if the response status is 200 (success)
      if (data.status === 200) {
        toast.info(data.message); // Success message
        console.log("===Success Data===" + JSON.stringify(data));
        refreshIngredients(); // Refresh ingredients on success
      } else {
        // Handle error case based on status
        if (data.status === 403) {
          toast.error(`Forbidden: ${data.message}`); // Forbidden error message
        } else if (data.status === 404) {
          toast.error(`Not Found: ${data.message}`); // Not found error message
        } else {
          toast.error(`Error: ${data.message}`); // Generic error message
          console.log("===Error Data===" + JSON.stringify(data.message));
        }
        console.log("===Error Data===" + JSON.stringify(data.message));
      }
    }}
  />
  
    
      )}

      {isAddModalOpen && (
        <AddIngredient
          categorySaved={categorySaved}
          onCategoryOpen={() => setIsAddCategoryModalOpen(true)}
          onClick={openAddModal}
          saveClick={saveHandler}
          ingredients={ingredients}
        />
      )}

      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">
            Inventory Items
              <button
                type="button"
                className="btn btn-outline-light ms-3"
                name="AddIngredient"
                title="New Ingredient"
                style={{ padding: "4px 8px 7px" }}
                onClick={openAddModal}
              >
                <FaPlus />
              </button>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <QuickAddIngredients 
            saveIngredient={quickAddIngredient} 
            ingredients={ingredients}
          />
          <hr />
          <div className="toolbar" />
          <Table 
            data={ingredients} 
            columns={COLUMNS} 
            defaultSortBy={defaultSortBy}
            onCellEdit={handleCellEdit}
            ModalComponent={TableModal}
          />
        </div>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar />
    </MainPanel>
  );
};

export default Ingredients;