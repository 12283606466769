import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import StorageLocations from "./StorageLocations";
import style from "../../assets/style/modal.module.css";
import { saveDataToStore, getDataFromStore,deleteDataFromStore } from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const STORE_NAME = 'inventory-count';
const DB_NAME = 'inventory-count';

export const AddNewInventoryCountModal = ({ saveClick, onClick }) => {
  const [_, handleHttpRequest] = useHttp();
  const isOnline = useNetworkStatus();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const addInventoryCount = async (values) => {
    try {
      const response = await handleHttpRequest({
        page: "inventory-count/add",
        method: "POST",
        data: values,
      });
      
      // Pass the response to the saveClick function with appropriate status
      saveClick(response, response?.status || 200);
    } catch (error) {
      console.error("Error adding inventory count:", error);
      saveClick(error, 500); // Handle errors with status code 500 or other
    }
  };

  const saveOfflineData = async (values) => {
    try {
      const data =[values];
      console.log(data);
      await saveDataToStore(DB_NAME, STORE_NAME, data,'storage_location_id');
      console.log('Data saved offline:', data);
    } catch (error) {
      console.error("Error saving offline data:", error);
    }
  };

  const syncOfflineData = async () => {
    try {
      const offlineData = await getDataFromStore(DB_NAME, STORE_NAME,'storage_location_id');

      if (offlineData?.length > 0) {
        for (const data of offlineData) {
          const response = await handleHttpRequest({
            page: "inventory-count/add",
            method: "POST",
            data: data,
          });

          // Pass the response to the saveClick function
          deleteDataFromStore(DB_NAME,STORE_NAME,data.storage_location_id);
          }

        // Optionally, clear the offline data after syncing
        console.log('Offline data synced');
      }
    } catch (error) {
      console.error("Error syncing offline data:", error);
    }
  };

  useEffect(() => {
    // Sync data when network status changes to online
    if (isOnline) {
      syncOfflineData();
    }
  }, [isOnline]);

  const formik = useFormik({
    initialValues: {
      date: "",
      storage_location_id: "",
    },
    validationSchema: Yup.object({
      date: Yup.date().required("Select an Inventory Count Date"),
      storage_location_id: Yup.number().required("Select a location"),
    }),
    onSubmit: (values) => {
      if (isOnline) {
        addInventoryCount(values);
      } else {
        saveOfflineData(values);
      }
    },
  });

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className={`${style["modal-header"]} text-bg-secondary`} data-bs-theme="dark">
                <h5 className="modal-title text-uppercase">INVENTORY COUNT</h5>
                <button
                  type="button"
                  className="btn-close dark-mode"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="col-sm-6">
                      <label className="lblrequired lbltheme" htmlFor="date">
                        Inventory Count Date*
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="date"
                        name="date"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.date && (
                        <div className="text-danger">{formik.errors.date}</div>
                      )}
                    </div>
                  </div>

                  <div className="modal-body row g-3">
                    <div className="col-sm-6">
                      <label className="lblrequired lbltheme" htmlFor="storage_location_id">
                        Location*
                      </label>
                      <StorageLocations
                        setStorage={() => {}}
                        value={formik.values.storage_location_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        storageSaved={false}
                      />
                      {formik.errors.storage_location_id && (
                        <div className="text-danger">{formik.errors.storage_location_id}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={style["modal-footer"]}>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewInventoryCount = (props) => {
  return ReactDOM.createPortal(
    <AddNewInventoryCountModal
      saveClick={props.saveClick}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};

export default AddNewInventoryCount;
