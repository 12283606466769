import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuppliersList from "../suppliers/supplierslist";
import units from "../../utils/units";
import useHttp from "../../hooks/useHttp";
// import useConversion from "../../hooks/useConversion";
import useUnitConversion from "../../hooks/useUnitConversion";
import { IoIosSave } from "react-icons/io";
import style from "../../assets/style/modal.module.css";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
export const AddSupplierIngredientModal = ({
  ingredient_id,
  saveClick,
  onClick,
}) => {
  const [hasError, handleHttpRequest] = useHttp();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const addSupplierIngredient = async (values) => {
    const response = await handleHttpRequest({
      page: "supplieringredient/add",
      method: "POST",
      data: {
        ...values,
        use_for_last_price: useLastPrice,
      },
    });

    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response.data, response.status);
    }
  };

  const [showConvFac, setShowConvFac] = useState(false);
  const [IsStandardUnit, convertUnit] = useUnitConversion();
  const dfltUnits = useRef();
  const convFactor = useRef(1);

  const [initialValues, setInitialValues] = useState({
    ingredient_id: 0,
    supplier_id: 0,
    supplier_item_number: "",
    cust_prod_nbr: "",
    supplier_item_desc: "",
    min_order_qty: 0,
    sold_by: "case",
    price_per_sold_by: 0,
    packs_per_sold_by: 1,
    pack_unit_measure: "",
    pack_unit_size: 1,
    pack_unit_desc: "",
    price_opt_price: 0,
    price_opt_measure: "",
    conv_factor: 1,
  });

  const getIngredient = async (id) => {
    const response = await handleHttpRequest({
      page: "ingredient/" + id,
      method: "GET",
    });
    if (!hasError) {
      dfltUnits.current = response.message.default_unit_measure;
      setInitialValues({
        ingredient_id: id,
        supplier_id: "",
        supplier_item_number: "",
        cust_prod_nbr: "",
        supplier_item_desc: response.message.name,
        min_order_qty: 0,
        sold_by: "case",
        price_per_sold_by: 0,
        packs_per_sold_by: 1,
        pack_unit_measure: response.message.default_unit_measure,
        pack_unit_size: 1,
        pack_unit_desc: "",
        price_opt_price: 0,
        price_opt_measure: response.message.default_unit_measure,
        conv_factor: 1,
      });
    }
  };

  useEffect(() => {
    getIngredient(ingredient_id.current);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      ingredient_id: Yup.number().required("Ingredient is required."),
      supplier_id: Yup.number().required(
        "Select a supplier for the Ingredient."
      ),
      supplier_item_number: Yup.string().max(100, "Item number is too long"),
      cust_prod_nbr: Yup.string().max(15, "Cust prod number is too long"),
      supplier_item_desc: Yup.string()
        .required("Supplier item description is required")
        .max(100, "Description is too long"),
      min_order_qty: Yup.number().notRequired(),
      sold_by: Yup.string().max(100, "Name is too long"),
      price_per_sold_by: Yup.number(),
      packs_per_sold_by: Yup.number(),
      pack_unit_measure: Yup.string().when("byCase", {
        is: "bycase",
        then: Yup.string().required(
          "Unit of measurement is required for by case option"
        ),
      }),
      pack_unit_size: Yup.number().required("Pack unit size is required"),
      pack_unit_desc: Yup.string().max(100, "Description is too long"),
      price_opt_price: Yup.number(),
      price_opt_measure: Yup.string().max(100, "Name is too long"),
      conv_factor: Yup.number().positive(),
    }),
    onSubmit: (values) => {
      if (byCase === "bycase") {
        values.sold_by = values.sold_by === "" ? "case" : values.sold_by;
      } else if (byCase === "bycustom") {
        values.sold_by = values.price_opt_measure;
        values.pack_unit_measure = values.price_opt_measure;
        values.price_per_sold_by = values.price_opt_price;
      }
      addSupplierIngredient(values);
      formik.resetForm();
    },
  });

  const pricePerCase = useRef(0);

  const calcCasePrice = () => {
    const {
      packs_per_sold_by = 1,
      pack_unit_size = 1,
      price_opt_price = 0,
      pack_unit_measure,
      price_opt_measure,
    } = formik.values;

    const unitMeasure =
      byCase === "bycustom" ? price_opt_measure : pack_unit_measure;

    if (!unitMeasure || !price_opt_measure) {
      pricePerCase.current = 0;
      return;
    }

    const totalUnits = Number(packs_per_sold_by) * Number(pack_unit_size);
    const convertedUnits =
      convertUnit(totalUnits, unitMeasure, price_opt_measure) || 1;
    pricePerCase.current = (Number(price_opt_price) * convertedUnits).toFixed(
      2
    );
  };

  const calPricePerItem = ({
    packsPerSoldBy = formik.values.packs_per_sold_by ?? 1,
    dfltUnitQty = formik.values.pack_unit_size ?? 1,
  }) => {
    let price = 0;
    const defaultConvFactor = formik.values.conv_factor ?? 1;
    convFactor.current = defaultConvFactor;

    // Handling price calculation based on case type
    if (byCase === "bycase") {
      price = Number(formik.values.price_per_sold_by);
      dfltUnitQty = Number(formik.values.pack_unit_size);
      console.log("Price bycase calculation:", price);
    } else if (byCase === "bycustom") {
      packsPerSoldBy = defaultConvFactor;
      dfltUnitQty = 1;
      price = Number(formik.values.price_opt_price);
      console.log("Price bycustom calculation:", price);
    } else {
      price = Number(formik.values.price_per_sold_by);
      console.log("Price before calculation:", price);
    }

    // Error handling for zero values to avoid division by zero
    if (packsPerSoldBy === 0 || dfltUnitQty === 0) {
      console.error("Error: Division by zero detected.");
      return "0.00";
    }

    // Calculate and return price per item
    const calculatedPrice = price / (packsPerSoldBy * dfltUnitQty);
    return calculatedPrice.toFixed(2);
  };

  const getPricePerItem = () => {
    const { pack_unit_measure, price_opt_measure, conv_factor, bycase } =
      formik.values;

    const pack_unit_size = formik.values.pack_unit_size ?? 1;
    const defaultConvFactor = conv_factor ?? 1;
    convFactor.current = defaultConvFactor;

    // Early exit if neither unit measures are present
    if (!pack_unit_measure && !price_opt_measure) {
      return;
    }

    // Calculate case price first
    calcCasePrice();

    let price_per_item;

    // Function to handle unit conversion and price calculation
    const calculatePrice = (unitMeasure) => {
      if (dfltUnits.current === unitMeasure) {
        return calPricePerItem({ dfltUnitQty: pack_unit_size });
      }

      // For non-standard units, apply conversion factor
      const isNonStandard =
        !IsStandardUnit(dfltUnits.current) || !IsStandardUnit(unitMeasure);
      if (isNonStandard) {
        return calPricePerItem({ packsPerSoldBy: defaultConvFactor });
      }

      // For standard units, apply conversion
      const convertedQty = convertUnit(
        pack_unit_size,
        unitMeasure,
        dfltUnits.current
      );
      return calPricePerItem({ dfltUnitQty: convertedQty });
    };

    // Case for "bycustom"
    if (bycase === "bycustom") {
      price_per_item = calculatePrice(price_opt_measure);
    } else {
      // Use appropriate measure depending on what's available
      price_per_item = calculatePrice(pack_unit_measure);
    }

    // Assign calculated price per item to formik values
    formik.values.price_per_item = price_per_item;

    return price_per_item;
  };

  const showConversionFactor = (e) => {
    const { name, value } = e.target;

    if (name === "pack_unit_measure" || name === "price_opt_measure") {
      const isDefaultUnit = value === dfltUnits.current;
      const isStandardDefaultUnit = IsStandardUnit(dfltUnits.current);
      const isStandardTargetValue = IsStandardUnit(value);

      if (isDefaultUnit) {
        setShowConvFac(false);
      } else if (
        !isStandardDefaultUnit ||
        (isStandardDefaultUnit && !isStandardTargetValue)
      ) {
        setShowConvFac(true);
      } else {
        setShowConvFac(false);
      }
    }
  };

  const handleBlur = (e) => {
    showConversionFactor(e);
    getPricePerItem();
  };

  const handleCalculate = (e) => {
    const { name, value } = e.target;

    // Set the form field value
    formik.setFieldValue(name, value);

    // Handle "bycase" or "bycustom" scenarios
    if (byCase === "bycustom") {
      formik.setFieldValue(
        "pack_unit_measure",
        formik.values.price_opt_measure
      );
      formik.setFieldValue(
        "price_per_sold_by",
        formik.values.price_opt_price || 0
      );
    }

    // Show conversion factor and recalculate price
    showConversionFactor(e);
    getPricePerItem();
  };

  const [byCase, setByCase] = useState("bycase");
  const setByHandler = (event) => {
    const value = event.target.value;
    setByCase(value);

    if (value === "bycase") {
      formik.setFieldValue("price_opt_measure", "");
      formik.setFieldValue("price_opt_price", 0);
      formik.setFieldValue("sold_by", "case");
    } else if (value === "bycustom") {
      formik.setFieldValue(
        "pack_unit_measure",
        formik.values.price_opt_measure
      );
      formik.setFieldValue("sold_by", formik.values.price_opt_measure);
      formik.setFieldValue("price_per_sold_by", formik.values.price_opt_price);
    }
    getPricePerItem();
  };

  const [useLastPrice, setUseLastPrice] = useState(true);
  const setUseLastPriceHandler = () => {
    setUseLastPrice((state) => !state);
  };

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} mx-5`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5
                  className="modal-title text-uppercase"
                  id="staticBackdropLabel"
                >
                  SUPPLIER OPTION
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form className="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="row mt-4">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="supplier_id"
                          >
                            Supplier*
                          </label>
                          <SuppliersList
                            value={formik.values.supplier_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <FormInput
                          label="Item Description*"
                          name="supplier_item_desc"
                          value={formik.values.supplier_item_desc}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4 col-lg-3">
                        <FormInput
                          label="Item Number"
                          name="supplier_item_number"
                          value={formik.values.supplier_item_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4 col-lg-3">
                        <FormInput
                          label="Cust Prod Nbr"
                          name="cust_prod_nbr"
                          value={formik.values.cust_prod_nbr}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4 col-lg-3">
                        <FormInput
                          label="Min Order Qty (by sellby)"
                          name="min_order_qty"
                          value={formik.values.min_order_qty}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div class="col-lg-3 col-sm-4 form-check">
                        <fieldset>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="name"
                            name="name"
                            value="bycase"
                            checked={byCase === "bycase"}
                            onChange={setByHandler}
                          />
                          <label
                            className="form-check-label lbltheme"
                            htmlFor="flexRadioDefault1"
                          >
                            prices by case
                          </label>
                        </fieldset>
                      </div>
                      <div class="col-lg-3 col-sm-6 form-check">
                        <fieldset>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="name"
                            name="name"
                            value="bycustom"
                            checked={byCase === "bycustom"}
                            onChange={setByHandler}
                          />
                          <label
                            className="form-check-label lbltheme"
                            htmlFor="flexRadioDefault2"
                          >
                            price by alternate unit
                          </label>
                        </fieldset>
                      </div>
                    </div>
                    {byCase === "bycustom" && (
                      <div className="row">
                        <div className="col-lg-3 col-sm-4">
                          <FormSelect
                            label="Unit of Measurement*"
                            name="price_opt_measure"
                            value={formik.values.price_opt_measure}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                            title="Select Measurement"
                            optionListing={units.map((unit) => (
                              <option key={unit.id} value={unit.unit}>
                                {unit.name}
                              </option>
                            ))}
                          />
                        </div>
                        <div className="col-lg-3 col-sm-4">
                          <FormInput
                            label="Price by Alternate Unit*"
                            name="price_opt_price"
                            value={formik.values.price_opt_price}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    )}
                    <hr></hr>
                    {byCase !== "bycustom" && (
                      <div className="row">
                        <div className="col-lg-2 col-sm-4">
                          <FormInput
                            label="Packs*"
                            name="packs_per_sold_by"
                            value={formik.values.packs_per_sold_by}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-lg-2 col-sm-4">
                          <FormInput
                            label="Unit Size*"
                            name="pack_unit_size"
                            value={formik.values.pack_unit_size}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-lg-2 col-sm-4">
                          {byCase === "bycase" ? (
                            <FormSelect
                              label="UoM*"
                              name="pack_unit_measure"
                              value={formik.values.pack_unit_measure}
                              onChange={handleCalculate}
                              onBlur={handleBlur}
                              title="Select Measurement"
                              optionListing={units.map((unit) => (
                                <option key={unit.id} value={unit.unit}>
                                  {unit.name}
                                </option>
                              ))}
                            />
                          ) : (
                            <FormSelect
                              label="UoM*"
                              name="pack_unit_measure"
                              value={formik.values.pack_unit_measure}
                              onChange={handleCalculate}
                              onBlur={handleBlur}
                              title="Select Measurement"
                              selected={formik.values.price_opt_measure}
                              optionListing={units.map((unit) => (
                                <option key={unit.id} value={unit.unit}>
                                  {unit.name}
                                </option>
                              ))}
                            />
                          )}
                        </div>
                        <div className="col-lg-2 col-sm-4">
                          <FormInput
                            label="Unit Description"
                            name="pack_unit_desc"
                            value={formik.values.pack_unit_desc}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-lg-2 col-sm-4">
                          {byCase === "bycase" ? (
                            <FormInput
                              label="Price per case*"
                              name="price_per_sold_by"
                              value={formik.values.price_per_sold_by}
                              onChange={handleCalculate}
                              onBlur={handleBlur}
                            />
                          ) : (
                            <FormInput
                              label="Price per case*"
                              name="price_per_sold_by"
                              value={pricePerCase.current}
                              disabled
                            />
                          )}
                        </div>
                        <div className="col-lg-2 col-sm-4">
                          <FormInput
                            label="Sells By Description"
                            name="sold_by"
                            value={formik.values.sold_by}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    )}
                    {showConvFac && (
                      <div className="row">
                        <div className="col-lg-3 col-sm-4">
                          <label
                            htmlFor="conv_factor"
                            class="lblrequired lbltheme"
                          >
                            Conversion Factor*:
                          </label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              {formik.values.packs_per_sold_by == 1
                                ? "(" +
                                  formik.values.pack_unit_size +
                                  " " +
                                  formik.values.pack_unit_measure +
                                  ")"
                                : formik.values.packs_per_sold_by +
                                  "*" +
                                  " (" +
                                  formik.values.pack_unit_size +
                                  " " +
                                  formik.values.pack_unit_measure +
                                  ")"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="conv_factor"
                              name="conv_factor"
                              value={formik.values.conv_factor}
                              onChange={handleCalculate}
                              onBlur={handleBlur}
                            />
                            <span class="input-group-text">
                              {dfltUnits.current}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mt-4">
                      <span className="lblrequired lbltheme fw-bold fst-italic">
                        {formik.values.pack_unit_measure !== "" ? (
                          <>
                            {byCase === "bycustom" ? (
                              <>
                                {"$" +
                                  getPricePerItem() + //isPricePerItem
                                  " per " +
                                  dfltUnits.current}
                              </>
                            ) : (
                              <>
                                {formik.values.packs_per_sold_by +
                                  "*" +
                                  " (" +
                                  formik.values.pack_unit_size +
                                  " " +
                                  formik.values.pack_unit_measure +
                                  ")" +
                                  " = $" +
                                  formik.values.price_per_sold_by +
                                  " " +
                                  formik.values.sold_by +
                                  "( $" +
                                  getPricePerItem() + //isPricePerItem " per " + dfltUnits.current +
                                  ")"}
                              </>
                            )}
                          </>
                        ) : (
                          <>Example: case = 4 * (5 lb packs)</>
                        )}
                      </span>

                      <input
                        type="hidden"
                        className="form-control"
                        id="price_per_item"
                        name="price_per_item"
                        value={formik.values.price_per_item}
                      />

                      <input
                        type="hidden"
                        className="form-control"
                        id="price_opt"
                        name="price_opt"
                        value={formik.values.price_opt}
                      />

                      <input
                        type="hidden"
                        className="form-control"
                        id="ingredient_id"
                        name="ingredient_id"
                        value={formik.values.ingredient_id}
                      />
                    </div>

                    <div class="form-check form-switch">
                      <input
                        className="form-check-input ckbtheme"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={useLastPrice}
                        onChange={setUseLastPriceHandler}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        Use for Ingredient's Last Paid Price per Default Unit
                      </label>
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <div className="row mt-2">
                    <div id="qadd-ingred-errmsg" className="error-msg">
                      {formik.errors.supplier_id
                        ? formik.errors.supplier_id
                        : formik.errors.supplier_item_desc
                        ? formik.errors.supplier_item_desc
                        : formik.errors.pack_unit_measure
                        ? formik.errors.pack_unit_measure
                        : null}
                    </div>
                  </div>

                  <div className="col d-flex align-self-end justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                  {/* <button type="submit" className="btn btn-primary">Save</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddSupplierIngredient = (props) => {
  return ReactDOM.createPortal(
    <AddSupplierIngredientModal
      ingredient_id={props.ingredient_id}
      saveClick={props.saveClick}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default AddSupplierIngredient;
