import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import { saveDataToStore, getDataFromStore } from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { toast } from "react-toastify";

const STORE_NAME = 'location-lists';
const DB_NAME ='location-db';

const StorageLocations = ({
  setStorage,
  value,
  onChange,
  onBlur,
  storageSaved,
}) => {
  const [storageLocations, setStorageLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [_, handleHttpRequest] = useHttp();
  const isOnline = useNetworkStatus();

  const getStorageLocations = async () => {
    setLoading(true);
    try {
      let data;
      if (!isOnline) {
        data = await getDataFromStore(DB_NAME, STORE_NAME);
        if (data.length === 0) {
         
        } else {
        
        }
      } else {
        const response = await handleHttpRequest({
          page: "storage-locations",
          method: "GET",
        });
        if (response.status) {
          toast.error("Failed to fetch data from server.");
          return;
        }
        data = response;
        await saveDataToStore(DB_NAME, STORE_NAME, response);
      }
      setStorageLocations(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error("An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const selectedStorage = storageLocations.find(
      (location) => location.id === parseInt(event.target.value, 10)
    );
    setStorage(selectedStorage);
    onChange(event);
  };

  useEffect(() => {
    getStorageLocations();
  }, [storageSaved, isOnline]);

  return (
    <select
      className="form-select"
      name="storage_location_id"
      id="storage_location_id"
      value={value || ''}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={loading}
    >
      <option key="0" value="">Select a location</option>
      {loading ? (
        <option disabled>Loading data...</option>
      ) : (
        storageLocations.length > 0 ? (
          storageLocations.map((storageLocation) => (
            <option key={storageLocation.id} value={storageLocation.id}>
              {storageLocation.name}
            </option>
          ))
        ) : (
          <option disabled>No data available</option>
        )
      )}
    </select>
  );
};

export default StorageLocations;
