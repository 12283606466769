import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import style from "../assets/style/table.module.css";
import GlobalFilter from "./GlobalFilter";

const Table = ({ data, columns, defaultSortBy, onCellEdit,ModalComponent }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null); // State for selected row details

   // Update handleCellClick to pass only additionalData
   const handleCellClick = (row) => {
    setSelectedRow(row.original); // Use row.original to access the row's data
  };

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const tableInstance = useTable(
    {
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => data, [data]),
      initialState: {
        sortBy: defaultSortBy || [],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    setGlobalFilter,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize, globalFilter } = state;

  // Function to handle cell edits
  const handleCellEdit = (rowIndex, columnId, newValue) => {
    if (onCellEdit) {
      onCellEdit(rowIndex, columnId, newValue);
    }
  };

  // EditableCell component for handling cell editing
  const EditableCell = ({ value, column, rowIndex }) => {
    const [editingValue, setEditingValue] = useState(value);

    const handleBlur = () => {
      handleCellEdit(rowIndex, column.id, editingValue);
    };

    const handleChange = (e) => {
      setEditingValue(e.target.value);
    };

    return (
      <input
        type="text"
        value={editingValue}
        onChange={handleChange}
        onBlur={handleBlur}
        className="form-control"
      />
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="">
          <span> Show </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setCurrentPage(0); // Reset to the first page when page size changes
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>
      <div className="overflow-auto">
        <table
          id={style["custom-table"]}
          {...getTableProps()}
          className="table display compact"
          width="100%"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      width: column.width, // Apply column width
                      textAlign: column.textAlign || "left", // Header alignment (left, center, right)
                    }}
                  >
                    {column.render("Header")}
                    <span className={style["table-sorting"]}>
                      {column.canSort &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <>
                              <span
                                className={`${style["table-sorting-up"]} ${style["active"]}`}
                              >
                                ▲
                              </span>
                              <span className={style["table-sorting-down"]}>
                                ▼
                              </span>
                            </>
                          ) : (
                            <>
                              <span className={style["table-sorting-up"]}>
                                ▲
                              </span>
                              <span
                                className={`${style["table-sorting-down"]} ${style["active"]}`}
                              >
                                ▼
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            <span className={style["table-sorting-up"]}>▲</span>
                            <span className={style["table-sorting-down"]}>
                              ▼
                            </span>
                          </>
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody id={style["custom-table-body"]} {...getTableBodyProps()}>
            {page.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  No data found!
                </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}
                  
                  onClick={() => handleCellClick(row)} // Pass only additionalData
                  style={{ cursor: "pointer",backgroundColor: selectedRow?.id === row.original.id ? "#f0f8ff" : "transparent", }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        
                        style={{
                          width: cell.column.width, // Apply column width
                          textAlign: cell.column.textAlign || "left", 
                          
                        }}
                      >
                        {cell.column.editable ? (
                          <EditableCell
                            value={cell.value}
                            column={cell.column}
                            rowIndex={row.index}
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <div>
          <button
            type="button"
            className="btn btn-outline-dark mx-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
        </div>
      </div>
        {/* Debugging: Check if selectedRow is set */}
        {selectedRow && console.log("Selected row data:", selectedRow)}

{/* Pass only additionalData to ModalComponent */}
{selectedRow && ModalComponent && (
  <ModalComponent
  id={selectedRow.id} // Use selectedRow for the ID
    onClose={() => setSelectedRow(null)}
  />
)}
    </div>
  );
};

export default Table;
