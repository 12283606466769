import axios from "axios";
import { useState, useCallback } from "react";

const useHttp = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const csrfUrl = process.env.REACT_APP_API_URL_SANCTUM;
  const [hasError, setHasError] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("auth_token"));

  // Cache for GET requests to avoid fetching the same data multiple times
  const cache = useState({})[0];

  const getCsrfToken = useCallback(async () => {
    try {
      await axios.get(`${csrfUrl}/csrf-cookie`, { withCredentials: true });
    } catch (error) {
      setHasError(true);
    }
  }, [csrfUrl]);

  const handleHttpRequest = async ({ page, method = "GET", data = null, cacheKey = null }) => {
    try {
      setHasError(false); // Reset the error state before making the request

      // Check if data is cached for GET request
      if (method === "GET" && cacheKey && cache[cacheKey]) {
        return cache[cacheKey];
      }

      const config = {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (method === "GET") {
        config.params = data;
      } else {
        config.data = data;
      }

      const response = await axios(`${apiUrl}/${page}`, config);

      // Cache the response for GET requests
      if (method === "GET" && cacheKey) {
        cache[cacheKey] = response.data;
      }

      return response.data;
    } catch (error) {
      setHasError(true);
      return error.response;
    }
  };

  return [hasError, handleHttpRequest, getCsrfToken];
};

export default useHttp;
