import { useState, useEffect } from 'react';
import useHttp from './useHttp';

export const useIngredients = () => {
  const [ingredients, setIngredients] = useState([]);
  const [beUpdated, setBEUpdated] = useState(false);
  const [hasError, handleHttpRequest] = useHttp();

  const getIngredients = async () => {
    const response = await handleHttpRequest({
      page: "ingredients",
      method: "GET",
    });
    if (!hasError) {
      setIngredients(response.message);
    }
  };

  useEffect(() => {
    getIngredients();
  }, [beUpdated]);

  const refreshIngredients = () => setBEUpdated(prev => !prev);

  return {
    ingredients,
    refreshIngredients,
    setIngredients
  };
};