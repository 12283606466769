import React, { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoIosSave } from "react-icons/io";
import { toast } from "react-toastify";
import { isIngredientNameUnique } from "../utils/validation";

const QuickAddIngredients = ({ saveIngredient, ingredients }) => {
  const [categories, setCategories] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();

  const getCategories = async () => {
    const response = await handleHttpRequest({
      page: "categories",
      method: "GET",
    });
    if (!hasError) {
      setCategories(response);
    }
  };

  const addIngredient = async (values) => {
    if (!isIngredientNameUnique(ingredients, values.name)) {
      toast.error("This ingredient name already exists!");
      return;
    }

    const response = await handleHttpRequest({
      page: "ingredient/add",
      method: "POST",
      data: values,
    });
    if (!hasError) {
      saveIngredient(response);
      toast.success("Ingredient added successfully!");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      default_unit_measure: "",
      default_unit_price: "",
      category_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Ingredient Name is required.")
        .max(100, "Ingredient name is too long")
        .test("unique", "This ingredient name already exists", 
          value => value ? isIngredientNameUnique(ingredients, value) : true
        ),
      default_unit_measure: Yup.string()
        .required("Select a Default Unit of Measurement for the Ingredient."),
      default_unit_price: Yup.number()
        .typeError("Price must be a number")
        .required("Last price is required."),
      category_id: Yup.number()
        .required("Select a category for the Ingredient."),
    }),
    onSubmit: (values) => {
      addIngredient(values);
    },
  });

  return (
    <div className="quickadd">
      <form onSubmit={formik.handleSubmit}>
        <small className="texttheme fw-bold">QUICK ADD</small>
        <div className="row">
          <div className="col-sm-3">
            <fieldset>
              <label htmlFor="name" className="lblrequired lbltheme">
                Item Name*
              </label>
              <input
                type="text"
                className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </fieldset>
          </div>
          <div className="col-sm-3">
            <fieldset>
              <label htmlFor="default_unit_measure" className="lblrequired lbltheme">
                Default UOM*
              </label>
              <select
                className={`form-select ${formik.touched.default_unit_measure && formik.errors.default_unit_measure ? 'is-invalid' : ''}`}
                name="default_unit_measure"
                id="default_unit_measure"
                value={formik.values.default_unit_measure}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Measurement</option>
                {units.map((unit) => (
                  <option key={unit.id} value={unit.unit}>
                    {unit.name}
                  </option>
                ))}
              </select>
              {formik.touched.default_unit_measure && formik.errors.default_unit_measure && (
                <div className="invalid-feedback">{formik.errors.default_unit_measure}</div>
              )}
            </fieldset>
          </div>
          <div className="col-sm-3 col-lg-2">
            <fieldset>
              <label htmlFor="default_unit_price" className="lblrequired lbltheme">
                Last Price*
              </label>
              <input
                type="text"
                className={`form-control ${formik.touched.default_unit_price && formik.errors.default_unit_price ? 'is-invalid' : ''}`}
                id="default_unit_price"
                name="default_unit_price"
                value={formik.values.default_unit_price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.default_unit_price && formik.errors.default_unit_price && (
                <div className="invalid-feedback">{formik.errors.default_unit_price}</div>
              )}
            </fieldset>
          </div>
          <div className="col-sm-3">
            <fieldset>
              <label htmlFor="category_id" className="lblrequired lbltheme">
                Category*
              </label>
              <select
                className={`form-select ${formik.touched.category_id && formik.errors.category_id ? 'is-invalid' : ''}`}
                name="category_id"
                id="category_id"
                value={formik.values.category_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Category</option>
                {categories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              {formik.touched.category_id && formik.errors.category_id && (
                <div className="invalid-feedback">{formik.errors.category_id}</div>
              )}
            </fieldset>
          </div>
          <div className="col d-flex align-self-end justify-content-start">
            <button
              type="submit"
              className="btn btn-link btn-just-icon"
              id="QuickAddIngred"
              name="QuickAddIngred"
              title="Add"
            >
              <IoIosSave className="save-icon" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuickAddIngredients;