import { useState } from "react";
import { FaCartShopping } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

const RecieveOrdersButton = ({  
  sendToCart,
  voidRemaining,
  disabled,
  id,  // Added id as a prop
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ontoggle = () => {
    setShowDropDown((state) => !state);
  };

  return (
    <div className="btn-group">
      <button
        className="btn btntheme"
        title="Send to Cart"
        onClick={() => {
          sendToCart(id);  // Using id instead of cell.value
        }}
        disabled={disabled}
      >
        <FaCartShopping style={{ fontSize: "16px" }} /> Send to Cart
      </button>
      <button
        type="button"
        className={`btn btntheme dropdown-toggle  ${showDropDown ? "show" : ""}`}
        data-bs-toggle="dropdown"
        aria-expanded={`${showDropDown ? "true" : "false"}`}
        onClick={ontoggle}
        disabled={disabled}
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className={`dropdown-menu ${showDropDown ? "show" : ""}`}>
        <li>
          <button
            className="dropdown-item btn btn-link iconthemerecv"
            title="Void Remaining"
            onClick={() => {
              voidRemaining(id);  // Using id instead of cell.value
            }}
          >
            <RiDeleteBin6Line style={{ fontSize: "16px" }} /> Void
          </button>
        </li>
      </ul>
    </div>
  );
};

export default RecieveOrdersButton;
