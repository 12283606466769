import React, { useState } from "react";
import ReactDOM from "react-dom";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";

export const DeleteIngredientModal = ({ deleteId, onCancle, onDelete }) => {
  const closeModal = () => {
    // deleteSupplier()
    onCancle();
  };

  const [hasError, handleHttpRequest] = useHttp();
  const deleteSupplier = async (id) => {
    try {
      const response = await handleHttpRequest({
        page: "ingredient/" + id,
        method: "DELETE",
      });
  
      console.log("===response==", response);
  
      // Ensure response has status and message fields
      if (!response || typeof response.status === 'undefined' || typeof response.message === 'undefined') {
        throw new Error("Invalid response structure.");
      }
  
      // Handle different status codes
      if (response.status === 200) {
        // Success
        onDelete([response.message, response.status]);
      } else if (response.status === 403) {
        // Forbidden: ingredient linked to supplier or inventory
        console.error("Forbidden: " + response.message);
        onDelete([response.message, response.status]);
      } else if (response.status === 404) {
        // Not found: ingredient doesn't exist
        console.error("Not Found: " + response.message);
        onDelete([response.message, response.status]);
      } else {
        // Other errors
        console.error("Error deleting Inventory:", response.message);
        onDelete([response.message, response.status]);
      }
    } catch (error) {
      // Handle any unexpected errors or network issues
      console.error("Request failed:", error.message || error);
      onDelete(["Failed to delete Inventory.", 500]);
    }
  };
  
  

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]}`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className={` ${style["modal-header"]}`}>
                <h5 className="modal-title" id="staticBackdropLabel">
                  Delete Item
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                Delete this Item?
              </div>
              <div className={style["modal-footer"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteSupplier(deleteId)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteIngredient = (props) => {
  return ReactDOM.createPortal(
    <DeleteIngredientModal
      deleteId={props.deleteId}
      onCancle={props.onClick}
      onDelete={props.onDelete}
    />,
    document.getElementById("modal")
  );
};
export default DeleteIngredient;
