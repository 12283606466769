import React, { useState, useEffect, useRef } from "react";
import useHttp from "../hooks/useHttp";

const TableModal = ({ id, onClose }) => {
  const [data, setData] = useState(null);
  const [count, setCount]=useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [_, handleHttpRequest] = useHttp();
  const fetchedIds = useRef(new Set()); // Track fetched ingredient IDs

  const getIngredient = async (ingredientId) => {
    if (!ingredientId || fetchedIds.current.has(ingredientId)) return; // Skip if already fetched
    fetchedIds.current.add(ingredientId);

    setLoading(true);
    setError(null);

    try {
      const response = await handleHttpRequest({
        page: `ingredient/${ingredientId}`,
        method: "GET",
      });

      const counts = await handleHttpRequest({
        page:`ingredient-inventorycount/${ingredientId}`
      })
      if (response.status === 200) {
        setData(response);
        setCount(isNaN(Number(counts)) ? 0 : Number(counts)); // Fallback to 0 if NaN
      } else {
        setError("Failed to fetch ingredient details.");
      }
    } catch (err) {
      setError("An error occurred while fetching ingredient details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(`Received ID: ${id}`);
    if (id) {
      getIngredient(id);
    }
  }, [id]);

  const closeModalOnClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (loading) {
    return (
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
        onClick={closeModalOnClickOutside}
        role="dialog"
        aria-labelledby="modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-title">Loading...</h5>
              <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="placeholder-glow">
                <div className="placeholder col-12 mb-3"></div>
                <div className="placeholder col-12 mb-3"></div>
                <div className="placeholder col-12 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
        onClick={closeModalOnClickOutside}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger">Error</h5>
              <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>{error}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose} aria-label="Close modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="modal fade show"
      style={{ display: "block" }}
      tabIndex="-1"
      onClick={closeModalOnClickOutside}
      role="dialog"
      aria-labelledby="modal-title"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal-title">Inventory Details</h5>
            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <th className="table-light" style={{ width: "30%" }}>Item Name</th>
                    <td>{data?.message.name }</td>
                  </tr>
                  <tr>
                    <th className="table-light">Category</th>
                    <td>{data?.message?.category?.name }</td>
                  </tr>
                  <tr>
                    <th className="table-light">Unit Measure</th>
                    <td>{data?.message?.default_unit_measure }</td>
                  </tr>
                  <tr>
                    <th className="table-light">Price</th>
                    <td>${data?.message?.default_unit_price }</td>
                  </tr>
                  <tr>
                    <th className="table-light">Storage Location</th>
                    <td>{data?.storage_location?.[0]?.name || "      "}</td>
                  </tr>
                  <tr>
                    <th className="table-light">Primary Supplier</th>
                    <td>{data?.message?.primary_supplier?.name || "     "}</td>
                  </tr>
                  <tr>
                    <th className="table-light">Secondary Supplier</th>
                    <td>{data?.message?.secondary_supplier?.name || "   "}</td>
                  </tr>
                  <tr>
                    <th className="table-light">Onhand Qty</th>
                    <td><strong>{count??0}</strong> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose} aria-label="Close modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableModal;