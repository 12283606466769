import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { ToastContainer, toast } from "react-toastify";
import { FaCircleNotch  , FaRegEdit, FaArrowCircleDown, FaArrowAltCircleUp  } from "react-icons/fa";
import {
  getDataFromStore,
  deleteDataFromStore,
  saveDataToStore,
  saveDataRequestStore,
} from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";

// Define constants for DB and Store names
const purchaseorderDB = "purchaseorder";
const purchaseorderStore = "purchaseorder";
const PurchaseOrdersDB = "PurchaseOrdersDB";
const PurchaseOrdersStore = "PurchaseOrdersStore";
const receiveorderDB = "receiveorder";
const receiveorderStore = "receiveorder";
const VOIDREMAINING_DB = "voidRemainingDB";
const VOIDREMAINING_STORE = "voidRemainingStore";
const SEND_TO_CARTDB = "SendToCartDB";
const SEND_TO_CARSTORE = "sendToCartStore";
const receiveorderDBUpdate = "receiveorderDBUpdate";
const receiveorderUpdateStore = "receiveorderDBUpdate";
const purchaseorderitemDB = "purchaseorderitem";
const purchaseorderitemStore = "purchaseorderitem";
const orderitemDB = "orderitem";
const orderitemStore = "orderitem";

const PurchaseOrder = () => {
  const [_, handleHttpRequest] = useHttp();
  const isOnline = useNetworkStatus();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [receiveOrder, setReceiveOrders] = useState([]);
  const [loading, setLoading] = useState(false);


  const savePurchaseOrders = async (order_id) => {
    try {
        const response = await handleHttpRequest({
            page: `purchaseorderitem-order/${order_id}`,
            method: "GET",
        });
        if (response && response.purchaseOrderItems) { // Check if the response is valid
            await saveDataRequestStore(PurchaseOrdersDB, PurchaseOrdersStore, response, order_id);
            setReceiveOrders(response.purchaseOrderItems); // Update receiveOrder correctly
        }
    } catch (error) {
        console.error("Error saving purchase orders:", error);
    }
};


  // Sync data for offline storage
  const syncOfflineData = async () => {
    try {
      // Sync all offline data with the server
      const syncData = async (db, store, endpoint, method, successCondition = (res) => res.status) => {
        const data = await getDataFromStore(db, store);
        if (data && data.length > 0) {
          for (const item of data) {
            const response = await handleHttpRequest({
              page: `${endpoint}/${item.id}`,
              method,
              data: item,
            });

            if (successCondition(response)) {
              await deleteDataFromStore(db, store, item.id);
            } else {
              console.log(`Failed to sync item ${item.id}:`, response);
            }
          }
        }
      };

      // Perform sync operations for various stores
      await Promise.all([
        syncData(SEND_TO_CARTDB, SEND_TO_CARSTORE, "purchaseorderitem-sendtocart", "GET"),
        syncData(VOIDREMAINING_DB, VOIDREMAINING_STORE, "receiveorder-void", "PUT"),
        syncData(receiveorderDBUpdate, receiveorderUpdateStore, "receiveorder", "PUT", (res) => res.status),
        syncData(receiveorderDB, receiveorderStore, "receiveorder","POST", (res) => res.status),
      ]);
      toast.success("Data synchronized successfully!");
    } catch (error) {
      console.error("Error syncing offline data:", error);
      toast.error("Failed to sync data.");
    }
  };

  // Save purchase orders for offline usage
  const saveDataForOffline = async (data) => {
    if (data && data.length > 0) {
      try {
        await saveDataToStore(purchaseorderDB, purchaseorderStore, data, null, "id", true);
        toast.success("Purchase orders saved offline.");
       } catch (error) {
        console.error("Error saving purchase orders offline:", error);
        toast.error("Failed to save purchase orders offline.");
      }
    }
  };

  // Fetch purchase orders from API or from offline storage
  const getPurchaseOrders = async () => {
    try {
      const response = await handleHttpRequest({ page: "purchaseorder", method: "GET" });
      if (!response.state) {
        setPurchaseOrders(response);
        await saveDataForOffline(response);
      }
    } catch (error) {
      console.error("Error fetching purchase orders:", error);
      toast.error("Failed to fetch purchase orders.");
    }
  };

  // Fetch data for each receive order offline
  const getRecieveOrderOffline = async () => {
    // if (!Array.isArray(receiveOrder) || receiveOrder.length === 0) {
    //     console.warn("receiveOrder is empty or not an array. Attempting to load from offline storage...");
    //     return;
    // }
    console.log("Receive Order updated:", receiveOrder);
    try {
        // Use map() to create an array of promises
        const results = await Promise.allSettled(
            receiveOrder.map((order) =>
                fetchData(order.id).then(() =>
                    console.log(`Successfully fetched data for Order ID: ${order.id}`)
                )
            )
        );

        // Log the results of each promise settlement
        results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
                console.log(`Successfully fetched data for Order ID: ${receiveOrder[index].id}`);
            } else {
                console.error(`Failed to fetch data for Order ID: ${receiveOrder[index].id}`, result.reason);
            }
        });
    } catch (error) {
        console.error("An unexpected error occurred during the fetch operation:", error);
    }
};

  

  const fetchData = async (orderItemId) => {
    try {
      console.log("=====item recieved"+orderItemId);
      const [orderItemResponse, receiveOrderResponse] = await Promise.all([
        handleHttpRequest({ page: `purchaseorderitem/${orderItemId}`, method: "GET" }),
        handleHttpRequest({ page: `receiveorder-order/${orderItemId}`, method: "GET" }),
      ]);

      if (!orderItemResponse.status) {
        await saveDataRequestStore(purchaseorderitemDB, purchaseorderitemStore, orderItemResponse, orderItemId);
      }

      if (!receiveOrderResponse.status) {
        await saveDataRequestStore(orderitemDB, orderitemStore, receiveOrderResponse, orderItemId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle fetch offline action
  const handleFetchOffline = async (id) => {
    setLoading(true);  // Start the loading state (freeze the page)
    console.log(`Fetching data for offline with ID: ${id}`);
  
    try {
      console.log("Starting savePurchaseOrders...");
        await savePurchaseOrders(id); // Ensure this completes successfully
        console.log("savePurchaseOrders completed successfully.");

       toast.success("Data fetched and saved successfully!");
    } catch (error) {
      console.error("Error fetching offline data:", error);
      toast.error("Failed to fetch offline data.");
    } finally {
      setLoading(false);  // End the loading state (unfreeze the page)
    }
  };
  

  // Handle sync action
  const handleSync = async () => {
    setLoading(true);  // Freeze the page by enabling loading state
    console.log("Syncing offline data...");
    try {
      await syncOfflineData(); // Wait for the sync to complete
      toast.success("Offline data synchronized successfully!");
    } catch (error) {
      console.error("Error syncing offline data:", error);
      toast.error("Failed to sync offline data.");
    } finally {
      setLoading(false);  // Restore page interactivity after sync is complete
    }
  };
  useEffect(() => {
    if (receiveOrder && receiveOrder.length > 0) {
      console.log("Starting getRecieveOrderOffline...");
      getRecieveOrderOffline(); // Only runs if the first operation succeeds
       console.log("getRecieveOrderOffline completed successfully.");
        // Perform any additional logic or state updates based on receiveOrder
        console.log("Receive Order updated:", receiveOrder);
    }
}, [receiveOrder]);
  useEffect(() => {
    if (!isOnline) {
      getDataFromStore(purchaseorderDB, purchaseorderStore).then((data) => setPurchaseOrders(data));
    } else {
      getPurchaseOrders();
    }
  }, [isOnline]);
  const purchaseOrderStatus = (orderStatus) => {
    const isPartReceived = orderStatus === "Part recvd";
    const isNotReceived = orderStatus === "Not recvd";
    const isFullyReceived = !isNotReceived && !isPartReceived;
  
    const badgeClass = isFullyReceived
      ? "text-bg-primary"
      : isPartReceived
      ? "text-bg-danger"
      : "text-bg-warning";
  
    const badgeText = isFullyReceived
      ? "Recvd"
      : isPartReceived
      ? "Part recvd"
      : "Not recvd";
  
    return <span className={`badge rounded-pill ${badgeClass}`}>{badgeText}</span>;
  };
  
  const defaultSortBy = [
    {
      id: "purchase_order_date", // Column accessor
      desc: true, // Descending order
    },
  ];
  
  const COLUMNS = [
    {
      Header: "Order Number",
      accessor: "purchase_order_number",
    },
    {
      Header: "Order Date",
      accessor: "purchase_order_date",
    },
    {
      Header: "Supplier",
      accessor: "supplier.name",
    },
    {
      Header: "$Order Total",
      accessor: "total_ordered_qty",
      Cell: ({ value }) => (value ? `$${value}` : ""),
    },
    {
      Header: "$Recvd Total",
      accessor: "received_total",
      Cell: ({ value }) => (value ? `$${value}` : ""),
    },
    {
      Header: "Status",
      accessor: "order_status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { order_status: orderStatus } = row.original || {};
        return orderStatus ? purchaseOrderStatus(orderStatus) : "";
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ value }) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-order-items/${value}`} className="themecolor">
              <FaRegEdit />
            </Link>
          </button>
  
          <button
            className="btn btn-link btn-just-icon icontheme"
            style={{ color: "#278766" }}
            onClick={() => handleFetchOffline(value)}
            title="Fetch for Offline"
          >
            <FaArrowCircleDown />
          </button>
  
          <button
            className="btn btn-link btn-just-icon icontheme"
            style={{ color: "#5844f2" }}
            onClick={handleSync}
            title="Sync"
          >
            <FaArrowAltCircleUp />
          </button>
        </div>
      ),
    },
  ];
  

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="hourglass-spinner">
            <FaCircleNotch  className="spinner-icon" />
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">ORDERS</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <Table data={purchaseOrders} columns={COLUMNS} defaultSortBy={defaultSortBy}/>
          </div>
        </div>
        <ToastContainer position="bottom-right" hideProgressBar />
      </MainPanel>
    </>
  );
  
};

export default PurchaseOrder;
