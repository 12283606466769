import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { ToastContainer, toast } from "react-toastify";
import ReceiveOrder from "./ReceiveOrder";
import ReceiveOrderButtonGroup from "../../components/groupButtons/ReceiveOrderButtonGroup";
import {getItemByKey, saveDataRequestStore} from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";
const PurchaseOrdersDB ="PurchaseOrdersDB";
const PurchaseOrdersStore ="PurchaseOrdersStore";
const VOIDREMAINING_DB = "voidRemainingDB";
const VOIDREMAINING_STORE = "voidRemainingStore";
const SEND_TO_CARTDB="SendToCartDB";
const SEND_TO_CARSTORE="sendToCartStore";

const EditPurchaseOrder = () => {
  const [_, handleHttpRequest] = useHttp();
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const { id: order_id } = useParams();
  const isOnline = useNetworkStatus();
  const [openReceiveOrder, setOpenReceiveOrder] = useState();
  const lineItem = useRef({ current: null, remaining_qty: null });
  const [purchaseOrderStateUpdate, setPurchaseOrderStateUpdate] =
    useState(false);
const getOfflinedata = async()=>{
const data =await getItemByKey(PurchaseOrdersDB,PurchaseOrdersStore,parseInt(order_id, 10));
if (data) {
  setPurchaseOrderItems(data.purchaseOrderItems); // Correctly accessing purchaseOrderItems
  setPurchaseOrder(data.purchaseOrder); // Correctly accessing purchaseOrder
} else {
  toast.info("There is no offline data");
}
};
  const getPurchaseOrders = async () => {
    const response = await handleHttpRequest({
      page: "purchaseorderitem-order/" + order_id,
      method: "GET",
    });
    if (!response.status) {
      setPurchaseOrderItems(response.purchaseOrderItems);
      setPurchaseOrder(response.purchaseOrder);
    } else {
      //
    }
  };
  const sendItemsToCartOffline = async (purchaseOrderItemId) => {
    await saveDataRequestStore(SEND_TO_CARTDB,SEND_TO_CARSTORE,purchaseOrderItemId,purchaseOrderItemId);

  };
  const voidRemainingOffline = async (purchaseOrderItemId) => {
    await saveDataRequestStore(VOIDREMAINING_DB,VOIDREMAINING_STORE,purchaseOrderItemId,purchaseOrderItemId);
  };
  const sendItemsToCart = async (purchaseOrderItemId) => {
    if(!isOnline){
      await sendItemsToCartOffline(purchaseOrderItemId);
     }else{
    const response = await handleHttpRequest({
      page: "purchaseorderitem-sendtocart/" + purchaseOrderItemId,
      method: "GET",
    });
    if (!response.status) {
      toast.info("Sending item to cart...");
      setPurchaseOrderStateUpdate((state) => !state);
    } else {
      toast.error(response.data[0]);
    }
  }
  };

  

  const voidRemaining = async (purchaseOrderItemId) => {
    await new Promise((resolve) => {
      alert("Void remaining unreceived items?");
      resolve();
    });
  if(!isOnline){
    await voidRemainingOffline(purchaseOrderItemId);
   }else{
    const response = await handleHttpRequest({
      page: "receiveorder-void/" + purchaseOrderItemId,
      method: "PUT",
    });
    if (!response.status) {
      toast.info(response);
      setPurchaseOrderStateUpdate((state) => !state);
    } else {
      toast.error(response.data[0]);
    }
  }
  };

  useEffect(() => {
    if(!isOnline){
      getOfflinedata();
    }else{
    getPurchaseOrders();
    }
  }, [isOnline,purchaseOrderStateUpdate]);

  const calcExtendedPrice = (cell) => {
    const extendedPrice =
      cell.row.original?.ordered_qty *
      cell.row.original?.supplieringredient.price_per_sold_by;
    return "$" + extendedPrice;
  };

  const COLUMNS = [
    {
      Header: "Item #",
      accessor: "supplieringredient.supplier_item_number",
    },
    {
      Header: "Description",
      accessor: "supplieringredient.supplier_item_desc",
    },
    {
      Header: "Packs",
      accessor: (row) => row.supplieringredient.price_opt_measure === null 
      ? row.supplieringredient.packs_per_sold_by 
      : "",
    },
    {
      Header: "Unit size",
      accessor: (row) => row.supplieringredient.price_opt_measure === null 
      ? `${row.supplieringredient.pack_unit_size} ${row.supplieringredient.pack_unit_measure}` 
      : "",
    }, 
    {
      Header: "Price per Item",
      accessor: "supplieringredient.price_per_item",
      Cell: (cell) => "$" + cell.value,
    },
    {
      Header: "Supplier UOM",
      accessor: "supplieringredient.sold_by",
    },
    {
      Header: "Unit Price",
      accessor: "supplieringredient.price_per_sold_by",
      Cell: (cell) => (cell.value ? "$" + cell.value : ""),
    },
    {
      Header: "Ordered Qty",
      accessor: "ordered_qty",
    },
   
    {
      Header: "Extended Price",
      accessor: "recievd_qty",
      Cell: (cell) => calcExtendedPrice(cell),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <ReceiveOrderButtonGroup
            cell={cell}
            editItem={toggleOpenReceiveOrder}
            
          />
        </div>
      ),
    },
  ];
  function getRemainingQtyById(id) {
    const item = purchaseOrderItems.find((item) => item.id === id);
    if (item) {
      const remainingQty = item.remaining_qty;
      return remainingQty;
  }
    return 0; // Return null if the item is not found
}
  //ReceiveOrder
  const toggleOpenReceiveOrder = (id = null) => {
    setOpenReceiveOrder((state) => !state);
    lineItem.current = id;
  
    const remaining_qty = getRemainingQtyById(id);
  
    // Fixing the assignment
    if (lineItem.current) {
      lineItem.current = { id, remaining_qty }; // Combine id and remaining_qty
    }
  };
  

  const handlePurchaseOrderUpdate = () => {
    setPurchaseOrderStateUpdate((state) => !state
  );
  };

  return (
    <>
      {openReceiveOrder && (
        <ReceiveOrder
          id={lineItem.current.id}
          remainingQty={getRemainingQtyById(lineItem.current.id)}
          updatePurchaseOrder={handlePurchaseOrderUpdate}
          onClick={toggleOpenReceiveOrder}
        />
      )}
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">{purchaseOrder?.supplier?.name}</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row mt-4">
              <div className="col-lg-3 col-sm-6">
                <div className="row">
                  <strong>Purchase Order Number:</strong>
                </div>
                <div className="row">
                  <label className="form-label fw-bold texttheme">
                    {purchaseOrder?.purchase_order_number}
                  </label>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="row">
                  <strong>Purchase Order Date:</strong>
                </div>
                <div className="row">
                  <label className="form-label fw-bold texttheme">
                    {purchaseOrder?.purchase_order_date}
                  </label>
                </div>
              </div>
             </div>
            <hr></hr>
            <Table data={purchaseOrderItems} columns={COLUMNS} />
          </div>
        </div>
        <ToastContainer position="bottom-right" hideProgressBar />
      </MainPanel>
    </>
  );
};

export default EditPurchaseOrder;
