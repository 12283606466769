import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { IoIosSave } from "react-icons/io";
import Table from "../../components/Table";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import {getItemByKey,saveDataRequestStore} from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { toast } from "react-toastify";
const purchaseorderitemDB = "purchaseorderitem";
const purchaseorderitemStore ="purchaseorderitem";
const orderitemDB = "orderitem";
const orderitemStore ="orderitem";
const receiveorderDB ="receiveorder";
const receiveorderStore = "receiveorder";
const receiveorderDBUpdate ="receiveorderDBUpdate";
const receiveorderUpdateStore = "receiveorderDBUpdate";


export const ReceiveOrderModal = ({
  orderItemId,
  remainingQty,
  updatePurchaseOrder,
  onClick,
}) => {
  const [_, handleHttpRequest] = useHttp();
  const isOnline = useNetworkStatus();
  console.log("Remaining Quantity:", remainingQty);
  const [initialValues, setInitialValues] = useState({
    receive_order_qty:remainingQty,
    receive_price: "",
    receive_date: "", //current date
    receipt_number: "",
    purchase_order_item_id: orderItemId,
    id: null,
  });
  const [purchaseOrderItem, setPurchaseOrderItem] = useState();
  const [receivedOrders, setReceivedOrders] = useState([]);
  const [editReceiveOrder, setEditReceiveOrder] = useState(false);

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };


  const saveForOffline = async (val) => {
    if(val.id==null){
      const key = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
      await saveDataRequestStore(receiveorderDB, receiveorderStore,val,key);
      
    }
    else{
      await saveDataRequestStore(receiveorderDBUpdate, receiveorderUpdateStore, val);
    }
  
  };
  const getPurchaseOrder = async () => {
    const response = await handleHttpRequest({
      page: "purchaseorderitem/" + orderItemId,
      method: "GET",
    });
    if (!response.status) {
      console.log("purchase order", response);
      await saveDataRequestStore(purchaseorderitemDB,purchaseorderitemStore,response,orderItemId);
      setPurchaseOrderItem(response);
      setInitialValues({
        receive_order_qty:remainingQty,
        receive_price:response?.supplieringredient.price_per_sold_by || '',
        receive_date: today,
        receipt_number: "NA",
        purchase_order_item_id: orderItemId,
        id: null,
      });
    }
  };

  const getReceivedOrder = async () => {
    const response = await handleHttpRequest({
      page: "receiveorder-order/" + orderItemId,
      method: "GET",
    });
    if (!response.status) {
      //set initialValues
      saveDataRequestStore(orderitemDB,orderitemStore,response,orderItemId);
      setReceivedOrders(response);
    }
  };
  const getOfflineData = async () => {
    const purchaseOrderitemData = await getItemByKey(purchaseorderitemDB, purchaseorderitemStore,parseInt(orderItemId, 10));
    if (purchaseOrderitemData) {
      console.log("purchaseOrderitemData");
      console.log(purchaseOrderitemData);
      setPurchaseOrderItem(purchaseOrderitemData);
    } else {
      toast.warn("You are offline and there is no cached data");
    }
    const recieveorderData = await getItemByKey(orderitemDB, orderitemStore,parseInt(orderItemId, 10));
    if (recieveorderData) {
      const result = Object.keys(recieveorderData)
  .filter(key => key !== "id") // Exclude the 'id' key
  .map(key => recieveorderData[key]);
      console.log("recieveorderData");
      setReceivedOrders(result);
      console.log(result);
    } else {
      toast.warn("You are offline and there is no cached data");
    }
  };

  useEffect(() => {
    if(!isOnline){
    getOfflineData();
    }else{
    getPurchaseOrder();
   
    getReceivedOrder();
    
  }}, [isOnline]);

  const receiveOrder = async (values) => {
    if (values.id == null) {
      const response = await handleHttpRequest({
        page: "receiveorder",
        method: "POST",
        data: values,
      });


      console.log("receive order response: ", response);
      if (!response.status) {
        getReceivedOrder();
        updatePurchaseOrder();
        
      } else {
      }
    } else {
      const response = await handleHttpRequest({
        page: "receiveorder/" + values.id,
        method: "PUT",
        data: values,
      });
      console.log("receive order update response: ", response);
      if (!response.status) {
        setEditReceiveOrder(false);
        // saveClick(response, 200);
        //notification
        getReceivedOrder();
        updatePurchaseOrder();
      } else {
        // saveClick(response.data, response.status);
        //notification
        //refresh- the columns --table
      }
    }
  };
  const today = new Date().toISOString().slice(0, 16); // 
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      receive_order_qty: Yup.number(),
      receive_price: Yup.number(),
      receive_date: Yup.date(),
      receipt_number: Yup.string(),
    }),
    onSubmit: (values) => {
      if(!isOnline){
        saveForOffline(values);
        
      }else{
        receiveOrder(values);
       }
       formik.resetForm();
      setInitialValues({
        receive_order_qty:remainingQty,
        receive_price: purchaseOrderItem?.supplieringredient.price_per_sold_by || '',
        receive_date: today,
        receipt_number: "NA",
        purchase_order_item_id: orderItemId,
        id: null,
      });
    },
  });

  const openEditReceiveItem = (item) => {
    console.log("edit receive item: ", item); //receipt_number
    setInitialValues({
      receive_order_qty: item.receive_order_qty,
      receive_price: item.receive_price,
      receive_date: item.receive_date,
      receipt_number: item.receipt_number,
      purchase_order_item_id: orderItemId,
      id: item.id,
    });
    setEditReceiveOrder(true);
  };

  const deleteReceiveItem = async (item) => {
    const response = await handleHttpRequest({
      page: "receiveorder/" + item.id,
      method: "DELETE",
    });
    console.log("Delete receive order line item", response);
    if (!response.status) {
      getReceivedOrder();
      updatePurchaseOrder();
    } else {
      // onDelete([response.data, response.status]);
    }
  };

  const openDeleteReceiveItem = async (item) => {
    console.log("delete receive item: ", item);
    await new Promise((resolve) => {
      alert("Delete this receive record?");
      resolve();
    });
    deleteReceiveItem(item);
  };

  const COLUMNS = [
    {
      Header: "Received Date",
      accessor: "receive_date",
    },
    {
      Header: "Reciept #",
      accessor: "receipt_number",
    },
    {
      Header: "Received Qty",
      accessor: "receive_order_qty",
    },
    {
      Header: "Receiving price",
      accessor: "receive_price",
    },
    {
      Header: "Type",
      accessor: "type",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            (cell.value == "Recvd" && "text-bg-success") ||
            (cell.value == "Voided" && "text-bg-danger")||
            (cell.value == "Sent To Cart" && "text-bg-warning")
          }`}
          style={{ color: "#000" }}
        >
          {cell.value}
        </span>
      ),
    },
    // {
    //   Header: "Actions",
    //   accessor: "id",
    //   disableSortBy: true,
    //   Cell: (cell) => (
    //     <>
    //       <div>
    //         <button
    //           className="btn btn-link btn-just-icon icontheme editcount themecolor"
    //           style={{ color: "#FF7300" }}
    //           onClick={() => {
    //             openEditReceiveItem(cell.row.original);
    //           }}
    //         >
    //           <FaRegEdit />{" "}
    //         </button>
    //         <button
    //           className="btn btn-link btn-just-icon icontheme "
    //           onClick={() => {
    //             openDeleteReceiveItem(cell.row.original);
    //           }}
    //         >
    //           <RiDeleteBin6Line />{" "}
    //         </button>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  LINE ITEM FOR{" "}
                  {purchaseOrderItem?.supplieringredient.supplier_item_desc}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-sm-6">
                        <strong>Item #</strong>
                        <div>
                          {
                            purchaseOrderItem?.supplieringredient
                              .supplier_item_number
                          }
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <strong>Item Description</strong>
                        <div>
                          {
                            purchaseOrderItem?.supplieringredient
                              .supplier_item_desc
                          }
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <strong>Qty ordered per sellby</strong>
                        <div>
                          {purchaseOrderItem?.ordered_qty}{" "}
                          {purchaseOrderItem?.supplieringredient.sold_by}
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <strong>Description</strong>
                        <div>
                          {purchaseOrderItem &&
                            purchaseOrderItem?.supplieringredient
                              .packs_per_sold_by +
                              "*(" +
                              purchaseOrderItem?.supplieringredient
                                .pack_unit_size +
                              "" +
                              purchaseOrderItem?.supplieringredient
                                .pack_unit_measure +
                              ")"}
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                     </form>
                  <hr></hr>
                  <Table data={receivedOrders} columns={COLUMNS} />
                </div>
                <div className={style["modal-footer"]}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReceiveOrder = (props) => {
  return ReactDOM.createPortal(
    <ReceiveOrderModal
      orderItemId={props.id}
      remainingQty={props.remainingQty}
      updatePurchaseOrder={props.updatePurchaseOrder}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default ReceiveOrder;
