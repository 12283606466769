import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import QuickAddInventory from "./QuickAddInventory";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import InventoryCountTable from "./InventoryCountTable";
import EditIngredientInventory from "./EditIngredientInventory";
import { saveDataRequestStore, getDataFromStore, getItemByKey,deleteDataFromStore } from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const inventoryStore = 'inventory-list';
const inventoryDB = 'inventory-db';
const inventoryUpdateDB = 'inventory-update';
const inventoryUpdateStore = 'inventory-update';



const InventoryCount = () => {
  const { id: inventory_id } = useParams();
  const [_, handleHttpRequest] = useHttp();
  const [inventories, setInventories] = useState([]);
  const isOnline = useNetworkStatus();
  const [ingredientsForLocation, setIngredientsForLocation] = useState([]);
  const [initialValue, setInitialValue] = useState({ date: "", location: "" });
  const navigate = useNavigate();

  const inventory_to_update = useRef({});
  const inventory_to_update_index = useRef({});
  const [editIngInventory, setEditIngInventory] = useState(false);

  // Utility to handle toast messages
  const showToast = (type, message) => {
    type === 'success' ? toast.info(message) : toast.error(message);
  };

  const fetchOfflineData = async () => {
    const data = await getDataFromStore(inventoryDB, inventoryStore);
    const filteredData = data.filter(item => item.id === parseInt(inventory_id, 10));
    if (filteredData.length > 0) {
      setInitialValue({
        date_time: filteredData[0].date_time,
        location: filteredData[0].storagelocation.name,
      });
    }
  };

  const saveOfflineUpdate = async (inventory) => {
    await saveDataRequestStore(inventoryUpdateDB, inventoryUpdateStore, inventory,inventory_id);
  };

  
  
  const getInventory = async () => {
    try {
      const response = await handleHttpRequest({
        page: `inventory-count/${inventory_id}`,
        method: "GET",
      });
      if (!response.status) {
        setInitialValue({
          date_time: response.date_time,
          location: response.storagelocation.name,
        });
        fetchIngredientsForLocation(response.storage_location_id);
      }
    } catch (error) {
      showToast('error', "Error fetching inventory data.");
    }
  };

  const fetchIngredientsForLocation = async (location_id) => {
    try {
      const response = await handleHttpRequest({
        page: `ingredients-by-location/${location_id}`,
        method: "GET",
      });
      if (!response.status) {
        setInventories(response);
        setIngredientsForLocation(response);
      }
    } catch (error) {
      showToast('error', "Error fetching ingredients for location.");
    }
  };

  useEffect(() => {
   if(isOnline){
    // syncOfflineUpdates();
     getInventory();
   }else{
     fetchOfflineData();
  }}, [isOnline]);

  const addIngredienttoInventory  = (values) => {
    // Find the index of the existing inventory item
    const index = inventories.findIndex(inv => inv.ingredient_id === values.ingredient_id);
  
    if (index !== -1) {
      // Update existing inventory item
      const updatedInventories = [...inventories];
      updatedInventories[index] = {
        ...updatedInventories[index],
        qty_on_hand: updatedInventories[index].qty_on_hand + values.qty_on_hand,
      };
      setInventories(updatedInventories);
    } else {
      // Add new inventory item
      setInventories(prevInventories => [
        ...prevInventories,
        {
          ingredient: values.ingredient,
          ingredient_id: values.ingredient_id,
          size_on_hand: values.size_on_hand,
          qty_on_hand: values.qty_on_hand,
        },
      ]);
    }
  };
  

  const handleEditIngInventory = (inventory, index) => {
    inventory_to_update.current = inventory;
    inventory_to_update_index.current = index;
    setEditIngInventory(true);
  };

  const handleUpdateIngInventory = (inventory, index) => {
    setEditIngInventory(false);
    if (index !== -1) {
      const updatedInventories = [...inventories];
      updatedInventories[index] = { ...inventories[index], ...inventory };
      setInventories(updatedInventories);
      showToast('success', "Ingredient count updated.");
     
    }
  };

  const saveAllIngInventory = async () => {
    try {
      if (!isOnline){ 
        await saveOfflineUpdate(inventories);
        showToast('success', "Data saved for offline successfully!");
        navigate("/inventorycount");
      }else{
      const response = await handleHttpRequest({
        page: `ingredients-inventory/update/${inventory_id}`,
        method: "PUT",
        data: { inventories },
      });
      if (!response.state) {
        showToast('success', "Data saved successfully!");
        navigate("/inventorycount", { state: { response } });
      } else {
        showToast('error', response.data);
      }
    }
    } catch {
      showToast('error', "Failed to save inventory data.");
    }
  };

  const closeEditIngInventory = () => {
    setEditIngInventory(false);
  };
  const confirmSaveAll = async () => {
    await new Promise((resolve) => {
      alert("Save the ingredients to inventory count?");
      resolve();
    });
    saveAllIngInventory();
  };


  const deleteAllIngInventory = async () => {
    try {
      const response = await handleHttpRequest({
        page: `inventory-count/${inventory_id}`,
        method: "DELETE",
        data: inventories,
      });
      if (!response.state) navigate("/inventorycount", { state: { response } });
      else showToast('error', response.data);
    } catch {
      showToast('error', "Failed to delete inventory data.");
    }
  };
  const confirmDeleteAll = async () => {
    await new Promise((resolve) => {
      alert("Delete the ingredients to inventory count?");
      resolve();
    });
    deleteAllIngInventory();
  };
 //update inventories from inventory count table
 const updateInventories = (value) => {
  setInventories(value);
};
  return (
    <>
      <MainPanel>
        {editIngInventory && (
          <EditIngredientInventory
            onClick={closeEditIngInventory}
            storagelocation={initialValue.location}
            IngInventory={inventory_to_update.current}
            IngInventoryIndex={inventory_to_update_index.current}
            updateIngInventory={handleUpdateIngInventory}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">Inventory Count</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row">
              <div className="col-lg-3 col-sm-4">{initialValue.date_time}</div>
              <div className="col-lg-3 col-sm-4">{initialValue.location}</div>
              <div className="col-sm-3">
                <button
                  className="btn btn-link btn-just-icon icontheme editcount"
                  onClick={confirmSaveAll}
                >
                  <FaSave className="themecolor" style={{ fontSize: "24px" }} />
                </button>
                <button
                  className="btn btn-link btn-just-icon icontheme editcount"
                  onClick={confirmDeleteAll}
                >
                  <RiDeleteBin6Line
                    className="themecolor"
                    style={{ fontSize: "24px" }}
                  />
                </button>
              </div>
            </div>
            <QuickAddInventory
              ingredientsForLocation={ingredientsForLocation}
              addIngredienttoInventory={addIngredienttoInventory}
            />
            <hr></hr>
            <InventoryCountTable
              data={inventories}
              setdata={updateInventories}
              // newInventory={newInventories}
              // setNewInventory={updateNewInventories}
              storagelocation={initialValue.location}
              editIngInventory={handleEditIngInventory}
            />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default InventoryCount;
