import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import DeleteModal from "../DeleteModal";
import { IoIosSave } from "react-icons/io";
import { FaPlus, FaList } from "react-icons/fa";
import MenuItemRecipeIngredient from "./MenuItemRecipeIngredient";
import AddRecipeIngredient from "./AddRecipeIngredient";
import EditRecipeIngredient from "./EditRecipeIngredient";
import MenuGroup from "../menugroup/MenuGroups";
import AddMenuGroup from "../menugroup/AddMenuGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditMenuItem = () => {
  const [_, handleHttpRequest] = useHttp();
  const { id: menuitem_id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: "",
    menu_group_id: "",
  });
  const [isAddRecIngModalOpen, setIsAddRecIngModalOpen] = useState();
  const [isRecipeIngUpdated, setIsRecipeIngUpdated] = useState();
  const [totalCost, setTotalCost] = useState(0);

  const setTotalCostIngRec = (value) => {
    setTotalCost(value);
  };
  /** Get Menu Item */
  const getMenuItem = async () => {
    const response = await handleHttpRequest({
      page: "menuitem/" + menuitem_id,
      method: "GET",
    });
    console.log("Response:", response); // Debugging: Check if response contains expected data
    if (response && response.status) { // Ensure response has data
      setInitialValues({
        name: response.name || "", // Fallback to empty string
        price: response.price || "",
        menu_group_id: response.menu_group_id || "",
      });
    }
  };
  useEffect(() => {
    if (menuitem_id) {
      getMenuItem();
    }
  }, [menuitem_id]);

  /* Edit Menu Item */
  const editMenuItem = async (values) => {
    const response = await handleHttpRequest({
      page: "menuitem/edit/" + menuitem_id,
      method: "PUT",
      data: values,
    });
    if (!response.status) {
      toast.info(response);
    } else {
      toast.info(response);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Menu item name is required.")
        .max(100, "Menu item name is too long"),
      price: Yup.number()
        .typeError("Price must be a number")
        .required("Enter the price"),
      menu_group_id: Yup.number().required("Select a menu group for the menu."),
    }),
    onSubmit: (values) => {
      editMenuItem(values);
    },
  });

  /** Add new Recipe or Ingredient to menu item  */
  const type = useRef("");
  const toggleRecipeIngredient = (typeOf = "") => {
    type.current = typeOf;
    setIsAddRecIngModalOpen((state) => !state);
  };
  const saveRecipeIngredient = (message, status) => {
    setIsAddRecIngModalOpen(false);
    toast.info(message);
    setIsRecipeIngUpdated((state) => !state);
  };

  /** Delete Menu Item Recipe/Ingredient  */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id, typeOf = "") => {
    type.current = typeOf;
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    toast.info(data[0]);
    setIsRecipeIngUpdated((state) => !state);
  };

  /** Edit Menu Item Recipe/Ingredient */
  const [isEditRecipeItem, setIsEditRecipeItem] = useState(false);
  const recIngItem = useRef(0);
  const toggleEditRecipeItem = (recipeItem_id = 0, typeOf = "") => {
    recIngItem.current = recipeItem_id;
    type.current = typeOf;
    setIsEditRecipeItem((state) => !state);
  };
  const saveEditRecipeItem = (message, status) => {
    setIsEditRecipeItem((state) => !state);
    toast.info(message);
    setIsRecipeIngUpdated((state) => !state);
  };

  /** Menu Group */
  const [isAddMenuGroupOpen, setIsAddMenuGroupOpen] = useState(false);
  const [menuGroupUpdated, setMenuGroupUpdated] = useState(false);
  const toggleAddMenuGroup = () => {
    setIsAddMenuGroupOpen((state) => !state);
  };
  const handleAddMenuGroup = (message) => {
    setIsAddMenuGroupOpen(false);
    setMenuGroupUpdated((state) => !state);
    // toast.info(message);
    toast(<ToastBody message={message} />);
  };
  console.log("Formik values:", formik.values);
  return (
    <>
      <MainPanel>
        {isAddMenuGroupOpen && (
          <AddMenuGroup
            saveClick={handleAddMenuGroup}
            onClick={toggleAddMenuGroup}
          />
        )}
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item={
              type.current == "Recipe"
                ? "menuitem-recipe"
                : "menuitem-ingredient"
            }
            name={
              type.current == "Recipe"
                ? "menu item recipe"
                : "menu item ingredient"
            }
          />
        )}
        {isEditRecipeItem && (
          <EditRecipeIngredient
            ingRecId={recIngItem.current}
            menuitem_id={menuitem_id}
            menuitem_name={initialValues.name}
            cancleClick={toggleEditRecipeItem}
            saveClick={saveEditRecipeItem}
            type={type.current}
          />
        )}
        {isAddRecIngModalOpen && (
          <AddRecipeIngredient
            menuitem_id={menuitem_id}
            menuitem_name={initialValues.name}
            saveClick={saveRecipeIngredient}
            cancleClick={toggleRecipeIngredient}
            type={type.current}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ textTransform: "uppercase" }}
              >
                {initialValues.name}
              </h5>
            </div>
          </div>
          <div className="">
            <div className="modal-body row g-3" style={{ padding: "1em 2em" }}>
              <form className="" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <fieldset>
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Menu Item Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-3 col-sm-4">
                    <fieldset>
                      <label htmlFor="menu_group_id" className="lbltheme">
                        Menu Group
                      </label>
                      <MenuGroup
                        onMenuGroupSave={menuGroupUpdated}
                        value={formik.values.menu_group_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col d-flex align-self-end">
                    <button
                      type="button"
                      className="btn btn-link btn-just-icon"
                      id="AddMenuGroupPop"
                      name="AddMenuGroupPop"
                      onClick={toggleAddMenuGroup}
                      title="New Menu Group"
                    >
                      <FaList className="themecolor" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <fieldset>
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 d-flex align-self-end justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  <div id="qadd-ingred-errmsg" className="error-msg">
                    {formik.errors.name
                      ? formik.errors.name
                      : formik.errors.price
                      ? formik.errors.price
                      : formik.errors.menu_group_id
                      ? formik.errors.menu_group_id
                      : null}
                  </div>
                </div>
              </form>
              <hr></hr>
              <div>
                {/* <AddRecipeItem
                  recipe_id={recipe_id}
                  saveClick={handleRecipeItemUpdate}
                /> */}
                <div className="row">
                  <div className="col-sm-8">
                    <button
                      className="btn btntheme"
                      onClick={() => {
                        toggleRecipeIngredient("Recipe");
                      }}
                    >
                      {" "}
                      <FaPlus /> Add Recipe
                    </button>
                    <button
                      className="btn btntheme mx-2"
                      onClick={() => {
                        toggleRecipeIngredient("Ingredient");
                      }}
                    >
                      {" "}
                      <FaPlus /> Add Ingredients
                    </button>
                  </div>
                  <div className="col-sm-4 text-end">
                    <div className="fw-bold">Total Cost $</div>
                    <h3 className="themecolor">
  ${!isNaN(totalCost) && totalCost !== undefined ? totalCost.toFixed(2) : "0.00"}
</h3>

                  </div>
                </div>
              </div>
              <div>
                <MenuItemRecipeIngredient
                  menuitem_id={menuitem_id}
                  edit_RecipeIngItem={toggleEditRecipeItem}
                  delete_RecipeIngItem={openDeleteModal}
                  isRecipeIngUpdated={isRecipeIngUpdated}
                  setTotalCostIngRec={setTotalCostIngRec}
                />
              </div>
            </div>
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

const ToastBody = ({ closeToast, toastProps }) => {
  <div>
    <h2>Notification</h2>
    <p>{toastProps.message}</p>
    {toastProps.position}
    {/* <button>Retry</button> */}
    <button onClick={closeToast}>close</button>
  </div>;
};

export default EditMenuItem;
