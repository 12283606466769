import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import DeleteModal from "../DeleteModal";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "../../components/Table";
import AddSaleItem from "./AddSaleItem";
import EditSaleItem from "./EditSaleItem";

const EditSale = () => {
  const [_, handleHttpRequest] = useHttp();
  const { id: sale_id } = useParams();
  const [initialValues, setInitialValues] = useState({
    date: "",
    total_sold_qty: "",
    total_sold_price: "",
  });
  const [saleItems, setSaleItems] = useState([]);
  const [isAddSaleItemOpen, setIsAddSaleItemOpen] = useState();
  const [isEditSaleItemOpen, setIsEditSaleItemOpen] = useState();
  const [isSalesItemUpdated, setIsSalesItemUpdated] = useState(false);

  /** Get Menu Item */
  const getSaleAndItems = async () => {
    const response = await handleHttpRequest({
      page: "sale-item/sale/" + sale_id,
      method: "GET",
    });
    if (!response.status) {
      setInitialValues(response.sale);
      setSaleItems(response.sale_items);
    }
  };
  useEffect(() => {
    getSaleAndItems();
  }, [isSalesItemUpdated]);

  /** Add new Recipe or Ingredient to menu item  */
  const toggleAddSaleItem = () => {
    setIsAddSaleItemOpen((state) => !state);
  };
  const saveSaleItem = (message, status) => {
    setIsAddSaleItemOpen(false);
    toast.info(message);
    setIsSalesItemUpdated((state) => !state);
  };

  /** Delete Menu Item Recipe/Ingredient  */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    toast.info(data[0]);
    setIsSalesItemUpdated((state) => !state);
  };

  const sale_item_id = useRef("");
  const toggleEditSaleItem = (id = 0) => {
    sale_item_id.current = id;
    setIsEditSaleItemOpen((state) => !state);
  };
  const saveEditSaleItem = (message, status) => {
    setIsEditSaleItemOpen(false);
    toast.info(message);
    setIsSalesItemUpdated((state) => !state);
  };
  const COLUMNS = [
    {
      Header: "Menu Item",
      accessor: "menuitem.name",
    },
    {
      Header: "Qty",
      accessor: "sold_qty",
      Cell: ({ value }) =>
        value !== undefined && !isNaN(value)
          ? Number(value).toFixed(2) // Ensure the value is a number and format it
          : "",
    },
    
    {
      Header: "Price",
      accessor: "sold_price",
      Cell: ({ value }) =>
        value !== undefined && !isNaN(value)
          ? `$${Number(value).toFixed(2)}` // Format with $ and two decimal places
          : "",
    },
    
    {
      Header: "Exc Price",
      accessor: "ext_total",
      Cell: ({ value }) =>
        value !== undefined && !isNaN(value)
          ? `$${Number(value).toFixed(2)}` // Format with $ and two decimal places
          : "",
    },
    
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon themecolor"
            onClick={() => {
              toggleEditSaleItem(cell.value);
            }}
          >
            <FaRegEdit />
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];
  return (
    <>
      <MainPanel>
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="sale-item"
            name="sale item"
          />
        )}
        {isEditSaleItemOpen && (
          <EditSaleItem
            sale_item_id={sale_item_id.current}
            cancleClick={toggleEditSaleItem}
            saveClick={saveEditSaleItem}
          />
        )}
        {isAddSaleItemOpen && (
          <AddSaleItem
            sale_id={sale_id}
            cancleClick={toggleAddSaleItem}
            saveClick={saveSaleItem}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h5
                className="modal-title text-uppercase"
                id="staticBackdropLabel"
              >
                SALES HISTORY
              </h5>
            </div>
          </div>
          <div className="">
            <div className="modal-body row g-3" style={{ padding: "1em 2em" }}>
              <div className="row mt-3">
                <div className="form-floating col-sm-6">
                  <div>
                    <strong className="text-uppercase">SALES DATE</strong>
                  </div>
                  <div>
                    <strong className="texttheme">{initialValues.date}</strong>
                  </div>
                </div>
                <div className="form-floating col-sm-3">
                  <div>
                    <strong className="text-uppercase"> QTY TOTAL</strong>
                  </div>
                  <div>
                    <strong className="texttheme">
                      {initialValues.total_sold_qty}
                    </strong>
                  </div>
                </div>
                <div className="form-floating col-sm-3">
                  <div>
                    <strong className="text-uppercase"> $SALES TOTAL</strong>
                  </div>
                  <div>
                  <strong className="texttheme">
  {initialValues.total_sold_price !== "" && !isNaN(initialValues.total_sold_price)
    ? Number(initialValues.total_sold_price).toFixed(2)
    : ""}
</strong>

                  </div>
                </div>
              </div>
              <hr></hr>
              <div>
                <div className="row">
                  <div className="col-sm-6">
                    <button
                      className="btn btntheme"
                      onClick={toggleAddSaleItem}
                    >
                      {" "}
                      <FaPlus /> Sales Line Item
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <Table data={saleItems} columns={COLUMNS} />
              </div>
            </div>
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default EditSale;
