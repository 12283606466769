// import style from '../../assets/style/table.module.css';
import useHttp from "../../hooks/useHttp";
import ButtonGroup from "../../components/ButtonGroup";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const SupplierIngredientsTable = ({
  column = [],
  table_values = [],
  onDelete,
  onSupplierSet,
  onSupplierIngEditOpen,
  onSuppIngEditSave,
}) => {
  const supplierSellsBy = (table_value) => {
    let supplier_sells_by =
      table_value.packs_per_sold_by == 1
        ? table_value.pack_unit_size + table_value.pack_unit_measure
        : table_value.packs_per_sold_by +
          " * (" +
          table_value.pack_unit_size +
          " " +
          table_value.pack_unit_measure +
          ")";
    let eq_to =
      " = $" + table_value.price_per_sold_by + " " + table_value.sold_by;
    return supplier_sells_by + " " + eq_to;
  };

  const onEditHandler = (id) => {
    onSupplierIngEditOpen(id);
  };

  const [hasError, handleHttpRequest] = useHttp();
  const deleteSupplier = async (id) => {
    // const response = await handleHttpRequest('supplieringredient/'+id, 'DELETE');
    const response = await handleHttpRequest({
      page: "supplieringredient/" + id,
      method: "DELETE",
    });
    if (!hasError) {
      // saveIngredient(response);
      onDelete([response.data, response.status]);
    }
  };
  const onDeleteHandler = (id) => {
    const result = window.confirm("Delete this Supplier Ingredient?");
    if (result) {
      deleteSupplier(id);
    }
  };
  const setPrimSecSupplier = async (id, set, supplier_id) => {
    const response = await handleHttpRequest({
      page: "ingredient/set-supplier/" + id,
      method: "PUT",
      data: {
        set: set,
        supplier_id: supplier_id,
      },
    });

    if (!hasError) {
      onSupplierSet();
    }
  };
  const onSetSupplierHandler = (id, set, supplier_id) => {
    setPrimSecSupplier(id, set, supplier_id);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {column.map((col) => (
            <th>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table_values.length == 0 ? (
          <tr style={{ padding: "1em" }}>No data found</tr>
        ) : (
          table_values.map((table_value) => {
            return (
              <tr>
                <td>
                  
                  {table_value.ingredient.primary_supplier_id ==
                  table_value.supplier_id ? (
                    <FaStar className="themecolor" />
                  ) : (
                    ""
                  )}
                  {table_value.ingredient.secondary_supplier_id ==
                  table_value.supplier_id ? (
                    <FaStarHalfAlt className="themecolor" />
                  ) : (
                    ""
                  )}
                  {table_value.supplier.name}
                </td>
                <td>{table_value.supplier_item_number}</td>
                <td>{table_value.supplier_item_desc}</td>
                <td>{table_value.sold_by}</td>
                <td>
                  {table_value.price_opt_measure == "" ||
                  table_value.price_opt_measure == null
                    ? table_value?.packs_per_sold_by
                    : ""}
                </td>
                <td>
                  {table_value.price_opt_measure == "" ||
                  table_value.price_opt_measure == null
                    ? `${table_value?.pack_unit_size} ${table_value?.pack_unit_measure}`
                    : ""}
                </td>
                <td>
                  {table_value.price_opt_measure == "" ||
                  table_value.price_opt_measure == null
                    ? table_value.price_per_sold_by
                    : table_value.price_opt_price}
                </td>
                <td>${table_value.price_per_item}</td>
                <td>
                  <ButtonGroup
                    ingredient_id={table_value.ingredient_id}
                    cell={table_value.id}
                    supplier_id={table_value.supplier_id}
                    onEditHandler={onEditHandler}
                    onDeleteHandler={onDeleteHandler}
                    onSetSupplierHandler={onSetSupplierHandler}
                  />
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default SupplierIngredientsTable;
