import { openDB } from 'idb';

// Open or create a database with an object store
const openDatabase = async (dbName, storeName, keyPath = 'id') => {
  if (!('indexedDB' in window)) {
    console.error("This browser doesn't support IndexedDB");
    return null;
  }
  return openDB(dbName, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        const store = db.createObjectStore(storeName, { keyPath, autoIncrement: keyPath === 'id' });
        // Example of creating an index for searching
        store.createIndex('by_name', 'name', { unique: false });
      }
    },
  });
};

// Save data to the object store, allowing custom keys
// Save all items to the object store at once, setting a custom key if provided
export const saveDataToStore = async (dbName, storeName, data, customKey = null, keyPath = 'id', clearStore = false) => {
  try {
    const db = await openDatabase(dbName, storeName, keyPath);
    if (!db) return false;

    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    // Optionally clear the store before saving
    if (clearStore) {
      await store.clear();
    }

    // Add each item in a single batch, optionally setting a custom key
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      const itemToStore = customKey ? { ...item, [keyPath]: `${customKey}-${index}` } : item;
      await store.put(itemToStore);
    }

    await tx.done;
    console.log(`All data successfully saved to ${storeName} in ${dbName}`);
    return true;
  } catch (error) {
    console.error(`Failed to save data to ${storeName} in ${dbName}:`, error);
    return false;
  }
};

export const saveDataRequestStore = async (dbName, storeName, data, customKey = null, keyPath = 'id', clearStore = false) => {
  try {
    const db = await openDatabase(dbName, storeName, keyPath);
    if (!db) return false;

    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    // Optionally clear the store before saving
    if (clearStore) {
      await store.clear();
    }

    // Convert customKey to an integer if it's provided
    const integerKey = customKey !== null ? parseInt(customKey, 10) : null;
    const itemToStore = integerKey ? { ...data, [keyPath]: integerKey } : data;

    await store.put(itemToStore);

    await tx.done;
    console.log(`All data successfully saved to ${storeName} in ${dbName}`);
    return true;
  } catch (error) {
    console.error(`Failed to save data to ${storeName} in ${dbName}:`, error);
    return false;
  }
};



// Get all data from the object store
export const getDataFromStore = async (dbName, storeName, keyPath = 'id') => {
  try {
    const db = await openDatabase(dbName, storeName, keyPath);
    if (!db) return [];

    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    const data = await store.getAll();
    console.log(`Retrieved data from ${storeName} in ${dbName}:`, data);
    return data;
  } catch (error) {
    console.error(`Failed to retrieve data from ${storeName} in ${dbName}:`, error);
    return [];
  }
};

// Retrieve an item by its key
export const getItemByKey = async (dbName, storeName, key) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return null;

    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    const item = await store.get(key);
    console.log(`Item with key ${key} retrieved from ${storeName} in ${dbName}:`, item);
    return item;
  } catch (error) {
    console.error(`Failed to retrieve item with key ${key} from ${storeName} in ${dbName}:`, error);
    return null;
  }
};

// Update an item by its key
export const updateItemByKey = async (dbName, storeName, key, updates) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return false;

    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    const item = await store.get(key);
    if (!item) return false;

    const updatedItem = { ...item, ...updates };
    await store.put(updatedItem);
    await tx.done;
    console.log(`Item with key ${key} updated in ${storeName} in ${dbName}`);
    return true;
  } catch (error) {
    console.error(`Failed to update item with key ${key} in ${storeName} in ${dbName}:`, error);
    return false;
  }
};

// Delete an item from the object store by key
export const deleteDataFromStore = async (dbName, storeName, key) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return false;

    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    await store.delete(key);
    await tx.done;
    console.log(`Item with key ${key} deleted from ${storeName} in ${dbName}`);
    return true;
  } catch (error) {
    console.error(`Failed to delete item with key ${key} from ${storeName} in ${dbName}:`, error);
    return false;
  }
};

// Clear all items from the object store
export const clearAllDataFromStore = async (dbName, storeName) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return false;

    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    await store.clear();
    await tx.done;
    console.log(`All items cleared from ${storeName} in ${dbName}`);
    return true;
  } catch (error) {
    console.error(`Failed to clear items from ${storeName} in ${dbName}:`, error);
    return false;
  }
};

// Get items by an indexed value
export const getItemsByIndex = async (dbName, storeName, indexName, value) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return [];

    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const index = store.index(indexName);

    const items = await index.getAll(value);
    console.log(`Items with ${indexName}=${value} retrieved from ${storeName} in ${dbName}:`, items);
    return items;
  } catch (error) {
    console.error(`Failed to retrieve items by index from ${storeName} in ${dbName}:`, error);
    return [];
  }
};

// Count all items in the store
export const countAllItemsInStore = async (dbName, storeName) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return 0;

    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    const count = await store.count();
    console.log(`Counted ${count} items in ${storeName} in ${dbName}`);
    return count;
  } catch (error) {
    console.error(`Failed to count items in ${storeName} in ${dbName}:`, error);
    return 0;
  }
};

// Check if an item exists by key
export const itemExistsByKey = async (dbName, storeName, key) => {
  try {
    const db = await openDatabase(dbName, storeName);
    if (!db) return false;

    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    const item = await store.getKey(key);
    const exists = item !== undefined;
    console.log(`Item with key ${key} exists in ${storeName} in ${dbName}: ${exists}`);
    return exists;
  } catch (error) {
    console.error(`Failed to check if item exists with key ${key} in ${storeName} in ${dbName}:`, error);
    return false;
  }
};
